import { FC, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { Transaction } from "../api/balance/balanceSchema";
import Sidebar from "../components/Sidebar";
import { route } from "../utils/constants/route";
import { TransactionStatus } from "./TransactionStatus";

export const TopUpSuccess: FC = () => {
  const history = useHistory();
  const topUpData = history.location.state as Transaction;

  const handleFinish = (): void => {
    history.push(route.WALLET);
  };

  useLayoutEffect(() => {
    if (!topUpData?.id) {
      handleFinish();
    }
  }, []);

  return (
    <div className="content">
      <Sidebar activeRoute={route.WALLET} />
      <div className="tab-content">
        <TransactionStatus
          title="Top Up Successful"
          text={`${topUpData?.cryptoAmount} ${topUpData?.cryptoCurrency}`}
          subText="has been credited to your account"
          button={{
            text: "Finish",
            onClick: handleFinish,
          }}
        />
      </div>
    </div>
  );
};
