import { useQuery } from "@tanstack/react-query";
import CloseNav from "components/CloseNav";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import { CurrencyDropdown } from "../components/CurrencyDropdown";
import { MaxInputValue } from "../components/MaxInputValue";
import { NumberInput } from "../components/NumberInput";
import Sidebar from "../components/Sidebar";
import Spiner from "../components/Spinner";
import { errorMessages } from "../utils/constants/errorMessages";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { alertToast } from "../utils/helpers/alert";
import { thousandSeperated } from "../utils/helpers/general";
import { useDebounce } from "../utils/hooks/useDebounce";
import { TabTitle } from "../utils/pagetitle";
import { verivicationStatus } from "../utils/constants/verificationStatus";
import { limits } from "../utils/constants/limits";

const Sell: FC = () => {
  TabTitle("Digital Nugget | Sell");
  const history = useHistory();

  const { data: user } = useQuery([queryNames.USER], userApi.getUserData);
  const { isLoading: walletIsLoading, data: wallet } = useQuery(
    [queryNames.WALLET],
    balanceApi.getState
  );

  const { data: currencies } = useQuery(
    [queryNames.CURRENCIES],
    balanceApi.getCurrencies
  );

  const [selectedCurrencies, setSelectedCurrencies] = useState({
    currency: "USD",
    currencyValue: "",
    crypto: "BTC",
    cryptoValue: "",
  });

  const debounce = useDebounce(selectedCurrencies, 100);

  const { data: exchangeData, isFetching: exchangeValueIsLoading } = useQuery(
    [queryNames.EXCHANGE_VALUE, debounce],
    () => {
      const payload = {
        from: {
          currency: selectedCurrencies?.crypto,
          type: "crypto",
          amount: +selectedCurrencies.cryptoValue,
        },
        to: {
          currency: selectedCurrencies?.currency,
          type: "fiat",
        },
      };

      return balanceApi.getExchangeRate(payload);
    },
    {
      enabled: !!debounce.cryptoValue,
    }
  );

  const cryptoValue = wallet?.cryptos.find((crypto) => {
    return crypto.symbol === selectedCurrencies?.crypto;
  });

  const handleSell = (): void => {
    if (user?.kycStatus !== verivicationStatus.VERIFIED) {
      alertToast({
        message: "Transaction cannot be completed without KYC verification",
      });

      return;
    }

    if (
      cryptoValue &&
      cryptoValue.cryptoAmount >= +selectedCurrencies.cryptoValue
    ) {
      localStorage.setItem(
        localStorageVariable.EXCHANGE,
        JSON.stringify(selectedCurrencies)
      );
      history.push(route.SELL_PAYMENT);
    } else {
      alertToast({ message: errorMessages.code1000 });
    }
  };

  return (
    <div>
      <div className="content">
        <Sidebar activeRoute={route.WALLET} />
        <div className="tab-content">
          <div className="tab-content__wrapper">
            <div className="body_position">
              <div className="converter_body">
                <CloseNav title={"Sell"} route={route.WALLET} />
                <div className="converter__wrapper converter__wrapper--buy">
                  <div className="converter__item">
                    <h3 className="h3">I want to sell</h3>
                    <div className="converter__input-wrapper">
                      <NumberInput
                        value={selectedCurrencies.cryptoValue}
                        onChange={(value: string): void => {
                          setSelectedCurrencies({
                            ...selectedCurrencies,
                            cryptoValue: value,
                          });
                        }}
                        placeholder="0.00"
                        onSubmit={handleSell}
                        max={limits.EXCHANGE}
                      />
                      <CurrencyDropdown
                        currencies={currencies?.cryptoCurrencies}
                        onClick={(row) => {
                          setSelectedCurrencies({
                            ...selectedCurrencies,
                            crypto: row.symbol,
                          });
                        }}
                        selectedCurrency={currencies?.cryptoCurrencies?.find(
                          ({ symbol }) => symbol === selectedCurrencies.crypto
                        )}
                      />
                    </div>
                    <div className="content-under-input">
                      <div className="approx">
                        Approx
                        {walletIsLoading || exchangeValueIsLoading ? (
                          <Spiner sell={true} />
                        ) : (
                          <>
                            &nbsp;
                            {thousandSeperated(
                              exchangeData?.exchangeRate || 0
                            ) || "0.00"}{" "}
                            USD
                          </>
                        )}
                      </div>
                      <MaxInputValue
                        value={cryptoValue?.cryptoAmount}
                        currency={selectedCurrencies.crypto}
                        onClick={(value: number): void =>
                          setSelectedCurrencies({
                            ...selectedCurrencies,
                            cryptoValue: value.toString(),
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Button
                disabled={+selectedCurrencies.cryptoValue === 0}
                onClick={handleSell}
                className="center"
              >
                Sell & Withdraw
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sell;
