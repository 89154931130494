import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  MessagePayload,
  onMessage,
} from "firebase/messaging";
import { userApi } from "./api/user/userApi";
import { MessageType } from "./utils/types/message";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID,
  measurementId: process.env.REACT_APP_FCM_MEASURMENT_ID,
};

const app = initializeApp(firebaseConfig);
const messaging = window.Notification ? getMessaging(app) : null;

export const messagingGetToken = async (): Promise<void> => {
  if (messaging) {
    const token = await getToken(messaging, {
      vapidKey: process.env.REACT_APP_FCM_VAPID_KEY,
    });
    console.log(token);

    await userApi.addFcmToken(token);
  }
};

if (messaging) {
  onMessage(messaging, (payload) => {
    const message = payload as MessageType;

    if (message.data.type === "payment_transfer_finished") {
      new Notification(message.notification.title, {
        body: message.notification.body,
        icon: `${process.env.REACT_APP_URL}/mstile-70x70.png`,
      });
    }
  });
}

export const onMessageListener = (): Promise<MessagePayload> =>
  new Promise((resolve) => {
    if (messaging) {
      onMessage(messaging, (message) => {
        resolve(message);
      });
    }
  });
