import { useQuery } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import Sidebar from "../components/Sidebar";
import { onMessageListener } from "../firebase";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { statusType } from "../utils/constants/status";
import { useSearchParams } from "../utils/hooks/useSearchParams";
import { StatusType } from "../utils/types/statusType";
import { TransactionStatus } from "./TransactionStatus";

const statusMessages = {
  [statusType.FULFILLED]: {
    title: "Success",
    subText: "You can navigate away from this screen.",
  },
  [statusType.REJECTED]: {
    title: "Failure",
    subText: "You can navigate away from this screen.",
  },
  [statusType.PENDING]: {
    title: "Pending",
    subText: (
      <div>
        Don't navigate away from this screen. <br />
        Paymnet is being processed.
      </div>
    ),
  },
};

const paymentMessageStatuses = {
  successful: statusType.FULFILLED,
  rejected: statusType.REJECTED,
  pending: statusType.PENDING,
};

export const Payment: FC = () => {
  const params = useSearchParams();
  const history = useHistory();

  const [status, setStatus] = useState<StatusType>(statusType.PENDING);

  useQuery(
    [queryNames.PAYMENT_STATUS],
    () => balanceApi.getPaymentStatus({ paymentId: params.transactionId }),
    {
      onSuccess: (data) => {
        if (data.status !== statusType.PENDING) {
          setStatus(data.status);
        }
      },
      onError: () => {
        history.push(route.WALLET);
      },
    }
  );

  useEffect(() => {
    onMessageListener().then((message) => {
      if (message.data) {
        setStatus(paymentMessageStatuses[message?.data?.status]);
      }
    });
  }, []);

  return (
    <div className="content">
      <Sidebar />
      <div className="tab-content">
        <TransactionStatus
          title={statusMessages[status].title}
          subText={statusMessages[status].subText}
          status={status}
        />
      </div>
    </div>
  );
};
