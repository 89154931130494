import { useMutation, useQuery } from "@tanstack/react-query";
import CloseNav from "components/CloseNav";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import Sidebar from "../components/Sidebar";
import { icons } from "../utils/constants/icons";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { verivicationStatus } from "../utils/constants/verificationStatus";
import { thousandSeperated } from "../utils/helpers/general";
import { TabTitle } from "../utils/pagetitle";
import { SelectedCurrencies } from "../utils/types/exchange";
import { limitsPlaceholder } from "../utils/helpers/limitsPlaceholder";

const SellPayment: FC = () => {
  TabTitle("Digital Nugget | Sell Payment ");
  const history = useHistory();

  const selectedCurrencies: SelectedCurrencies = JSON.parse(
    localStorage.getItem(localStorageVariable.EXCHANGE) || "{}"
  );

  const payload = {
    from: {
      currency: selectedCurrencies.crypto,
      type: "crypto",
      amount: +selectedCurrencies.cryptoValue,
    },
    to: {
      currency: selectedCurrencies.currency,
      type: "fiat",
    },
  };

  const { data: user } = useQuery([queryNames.USER], userApi.getUserData);
  const { data: exchangeData } = useQuery(
    [queryNames.EXCHANGE_VALUE],
    () => balanceApi.getExchangeRate(payload),
    {
      enabled: !!selectedCurrencies?.crypto,
    }
  );

  const { mutate: sellCryptoMutation, isLoading } = useMutation(
    balanceApi.sellCrypto,
    {
      onSuccess: (data) => {
        localStorage.removeItem(localStorageVariable.EXCHANGE);
        history.push({
          pathname: route.SELL_SUCCESS,
          state: data,
        });
      },
    }
  );

  const handleSellCrypto = (): void => {
    if (isLoading) {
      return;
    }

    sellCryptoMutation(payload);
  };

  useEffect(() => {
    if (
      !selectedCurrencies?.crypto ||
      user?.kycStatus !== verivicationStatus.VERIFIED
    ) {
      history.push(route.WALLET);
    }
  }, []);

  return (
    <div className="content">
      <Sidebar activeRoute={route.WALLET} />
      <div className="tab-content">
        <div className="tab-content__wrapper">
          <div className="body_position">
            <div className="converter_body">
              <CloseNav title={"Sell"} route={route.SELL} />
              <div className="converter__wrapper converter__wrapper--buy">
                <div className="converter__item">
                  <h3 className="h3">You will spend &nbsp;</h3>
                  <div className="converter__input-wrapper input_disabled">
                    <input
                      type="text"
                      placeholder={limitsPlaceholder()}
                      className="converter__input input_disabled"
                      value={selectedCurrencies.cryptoValue}
                      readOnly
                    />
                    <div className="crypto-value">
                      <img
                        src={
                          selectedCurrencies?.crypto === "USDT"
                            ? icons.usdt
                            : selectedCurrencies?.crypto === "ETH"
                            ? icons.ethereum
                            : icons.btc
                        }
                        alt={"crypto-icon"}
                        height={"24"}
                        width={"24"}
                        className="crypto-icon"
                      />
                      {selectedCurrencies.crypto}
                    </div>
                  </div>
                </div>
                <div className="converter__item2">
                  <h3 className="h3">Withdraw to</h3>
                  <div className="converter__input-wrapper ">
                    <input
                      value={
                        user?.card?.brand && user?.card?.lastDigits
                          ? user?.card?.brand + " * " + user?.card?.lastDigits
                          : "No Card"
                      }
                      type="text"
                      readOnly
                      className="converter__input converter__input-wrapper--start input_disabled upperCaseText"
                    />
                    <div className="input-text input_disabled crypto-value">
                      ≈ {thousandSeperated(exchangeData?.exchangeRate || 0)}{" "}
                      {selectedCurrencies.currency}
                    </div>
                  </div>
                  <div className="prompt">
                    <img src={icons.info_icone} alt="" />
                    You can only withdraw to cards that you have made a deposit
                    from.
                  </div>
                </div>
              </div>
            </div>
            <Button
              loading={isLoading}
              onClick={handleSellCrypto}
              className="center"
            >
              Sell & Withdraw
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellPayment;
