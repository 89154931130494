import * as Payment from "payment";

export const thousandSeperated = (number: number): string => {
  return Number(number).toLocaleString("en", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const getCurrencySymbol = (currency: string): string => {
  return (0)
    .toLocaleString("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, "")
    .trim();
};

function clearNumber(value = ""): string {
  return value.replace(/\D+/g, "");
}

export function formatCreditCardNumber(value: string): string {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = clearNumber(value);
  let nextValue: string;

  switch (issuer) {
    case "amex":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 15)}`;
      break;
    case "dinersclub":
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        10
      )} ${clearValue.slice(10, 14)}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
        4,
        8
      )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

export function formatCVC(value: string): string {
  const clearValue = clearNumber(value);
  const maxLength = 3;

  return clearValue.slice(0, maxLength);
}

export function formatExpirationDate(value: string): string {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

export function getFirstNonZeroStr(n: number): number {
  if (n >= 1) return -1;
  const s = n.toString();
  const m = s.match(/^0\.(0*)[\d]+$/);
  return m?.[1].length || -1;
}

export const castBooleanString = (booleanString: string): boolean => {
  return booleanString === "true";
};

export const capitalize = (value: string): string => {
  return value[0].toUpperCase() + value.slice(1);
};
