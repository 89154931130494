import { AxiosInstance } from "axios";
import { endpoints } from "../../utils/constants/endpoints";
import { axiosInstance } from "../axiosInstance";
import {
  PendingRequestActionPayload,
  PendingRequestsResponse,
} from "./pendingRequestSchema";

class PendingRequestApi {
  #requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.#requestInstance = axiosInstance;
  }

  getPendingRequests = async (): Promise<PendingRequestsResponse> => {
    return this.#requestInstance.get(endpoints.PENDING_REQUESTS);
  };

  pendingRequestAction = async (
    action: PendingRequestActionPayload
  ): Promise<void> => {
    return this.#requestInstance.put(
      `${endpoints.PENDING_REQUESTS}/${action.id}/${action.type}`
    );
  };
}

export const pendingRequestApi = new PendingRequestApi(axiosInstance);
