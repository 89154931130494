import { FC, PropsWithChildren } from "react";
import { Button } from "../../components/Button";
import { TransactionTitle } from "../../components/TransactionTitle";

type SuccessPopupProps = {
  title: string;
  close: () => void;
};

export const SuccessPopup: FC<PropsWithChildren<SuccessPopupProps>> = ({
  title,
  close,
  children,
}) => {
  return (
    <div className="offcanvas-content">
      <div className="offcanvas-top">
        <TransactionTitle className="offcanvas-header">
          {title}
        </TransactionTitle>
        {children}
      </div>
      <div className="offcanvas-bottom">
        <div className="modal-buttons">
          <Button onClick={close}>Finish</Button>
        </div>
      </div>
    </div>
  );
};
