import { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { Button } from "../components/Button";
import CloseNav from "../components/CloseNav";
import FooterOfWeb from "../components/FooterOfWeb";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { route } from "../utils/constants/route";
import { TabTitle } from "../utils/pagetitle";
import { icons } from "utils/constants/icons";

const IdVerification: FC = () => {
  TabTitle("Digital Nugget | ID Verification");
  const history = useHistory();

  const handleSkip = (): void => {
    if (localStorage.getItem(localStorageVariable.EXCHANGE)) {
      history.push(route.BUY_PAYMENT);
    } else {
      history.push(route.WALLET);
    }
  };

  const handleContinue = (): void => {
    history.push({
      pathname: route.VERIFICATION,
      state: route.LOGIN,
    });
  };

  return (
    <div className="containerr">
      <header className="header">
        <div className="header__container">
          <div className="logo">
            <Link to={route.HOME}>
              <img src={icons.digital_nugget_logo} alt="logo" />
              {/* <button className="nixxeTittle">NIXXE</button> */}
            </Link>
          </div>
        </div>
      </header>

      <div className="verify-wrapper verify-wrapper--ver">
        <div className="sub-header">
          <CloseNav step={2} progressBar prog={100} title={"Scan your ID"} />
        </div>
        <p className="scanIDtext">
          You can deposit but you won't be able to transfer or withdraw until
          you have provided an ID
        </p>
        <span className="acceptedIDs">
          <p>Accepted ID's</p>
          <ul>
            <li>Driving License</li>
            <li>Passport</li>
          </ul>
        </span>
        <div className="button_position_scanId">
          <Button
            variaint="transparent"
            className="scanIDbtn"
            onClick={handleSkip}
          >
            Skip
          </Button>
          <Button onClick={handleContinue} style={{ marginTop: "12px" }}>
            Continue
          </Button>
        </div>
      </div>
      <FooterOfWeb />
    </div>
  );
};

export default IdVerification;
