import * as classNames from "classnames";
import { FC } from "react";

type PaymentLoaderProps = {
  className?: string;
};

export const PaymentLoader: FC<PaymentLoaderProps> = ({ className }) => {
  return (
    <div className={classNames("lds-ellipsis", className)}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};
