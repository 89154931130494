import { AxiosInstance } from "axios";
import { endpoints } from "../../utils/constants/endpoints";
import { axiosInstance } from "../axiosInstance";
import { CountryResponse } from "./countrySchema";

class CountryApi {
  #requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.#requestInstance = axiosInstance;
  }

  getCountries = async (): Promise<CountryResponse> => {
    return this.#requestInstance.get(endpoints.COUNTRIES);
  };
}

export const countryApi = new CountryApi(axiosInstance);
