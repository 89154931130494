import { useMutation, useQuery } from "@tanstack/react-query";
import * as classNames from "classnames";
import { ChangeEvent, FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { countryApi } from "../api/country/countryApi";
import { Country } from "../api/country/countrySchema";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import CloseNav from "../components/CloseNav";
import FooterOfWeb from "../components/FooterOfWeb";
import { messagingGetToken } from "../firebase";
import { allflags, icons } from "../utils/constants/icons";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { screenSize } from "../utils/constants/screenSizes";
import { useSize } from "../utils/hooks/useSize";
import { TabTitle } from "../utils/pagetitle";

const SignUp: FC = () => {
  TabTitle("Digital Nugget | Sign Up");

  const history = useHistory();
  const isMobile = useSize(screenSize.MOBILE);

  const { data: countries } = useQuery(
    [queryNames.COUNTRIES],
    countryApi.getCountries
  );
  const { isLoading, mutate: updateUserDataMutation } = useMutation(
    userApi.updateUserData,
    {
      onSuccess() {
        localStorage.removeItem(localStorageVariable.OTP);
        localStorage.removeItem(localStorageVariable.PHONE_NUMBER);
        localStorage.removeItem(localStorageVariable.COUNTRY);
        localStorage.setItem(localStorageVariable.USER_IS_ACTIVE, "true");
        messagingGetToken();
        history.push(route.VERIFY_IDENTITY);
      },
    }
  );

  const [search, setSearch] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [changeTerms, setChangeTerms] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(
    JSON.parse(localStorage.getItem(localStorageVariable.COUNTRY) || "{}")
  );

  const isValid = firstName && lastName && selectedCountry?.name && changeTerms;

  const checkTermsVersion = () => {
    setChangeTerms(!changeTerms);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const handleContinue = () => {
    if (!isValid) {
      return;
    }

    const payload = {
      firstName: firstName,
      lastName: lastName,
      country: selectedCountry.cca2,
      termVersion: "1.0",
      currency: "USD",
    };

    updateUserDataMutation(payload);
  };

  const handleClickLogo = () => {
    localStorage.clear();
    // history.push(route.HOME);
  };

  // useEffect(() => {
  //   if (localStorage.getItem(localStorageVariable.USER_IS_ACTIVE) !== "false") {
  //     history.push(route.LOGIN);
  //   }
  // }, []);

  return (
    <>
      <div className="containerr">
        {!isMobile && (
          <header className="header">
            <div className="header__container">
              <div className="logo">
                <Link
                  to={route.HOME}
                  className="sidebar__logo"
                  onClick={handleClickLogo}
                >
                  <img src={icons.digital_nugget_logo} alt="logo" />
                </Link>
                {/* <button onClick={handleClickLogo} className="nixxeTittle">
                  NIXXE
                </button> */}
              </div>
            </div>
          </header>
        )}
        <div className="auth-wrapper">
          <div className="sub-header">
            <CloseNav
              title={"New here? Let's get you setup"}
              prog={50}
              progressBar
              step={1}
            />
          </div>
          <div className="user-info-form">
            <div className="user-info-item">
              <label className="text-label">First Name</label>
              <input
                type="text"
                className="text-input textCapitalize"
                placeholder="Enter your first name"
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="user-info-item">
            <label className="text-label">Last Name</label>
            <input
              type="text"
              className="text-input textCapitalize"
              placeholder="Enter your last name"
              onChange={(e) => setlastName(e.target.value)}
            />
          </div>
          <div className="user-info-item">
            <label className="text-label">Country of Residence</label>
            <button
              className="text-input inputArrowStyleImage"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
            >
              <p className="button_title d-flex">
                {selectedCountry?.name && (
                  <>
                    <span className="d-flex align-items-center ps-2 me-2">
                      <img
                        src={selectedCountry.flagUrl}
                        className="flag_img ms-2"
                        alt=""
                      />
                    </span>
                    {selectedCountry.name}
                  </>
                )}
              </p>
              <img
                src={icons.downArrow}
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
                alt="arrow"
              />
            </button>
            <div
              className="modal fade"
              id="staticBackdrop"
              data-bs-backdrop="static"
              data-bs-keyboard="false"
              tabIndex={-1}
              aria-labelledby="staticBackdropLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog  modal-fullscreen">
                <div className="modal-content">
                  <div className="modal-body content-center-modal">
                    <CloseNav title={"Select country of residence"} close />
                    <div className="searchImg">
                      <img src={icons.search_icon} alt="" />
                      <input
                        type="select"
                        className="inputStyle"
                        placeholder="Search"
                        value={search}
                        onChange={handleSearch}
                      />
                    </div>
                    {countries
                      ?.filter((country) => {
                        return country.name
                          .toLowerCase()
                          .includes(search.toLowerCase());
                      })
                      ?.map((country) => {
                        return (
                          <div
                            key={country.name + country.cca2}
                            className=" d-flex "
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <button
                              className={classNames("styleofinputsmodal", {
                                modalBorder:
                                  selectedCountry?.cca2 === country.cca2,
                              })}
                              onClick={(): void => {
                                setSelectedCountry(country);
                                setSearch("");
                              }}
                            >
                              <div className="d-flex justify-content-between w-100">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={allflags + country.cca2 + ".png"}
                                    className="ps-2 flag_img"
                                    alt=""
                                  />
                                  <p className="decimalText">{country.name}</p>
                                </div>
                                <div className="d-flex align-items-center">
                                  {selectedCountry?.cca2 === country.cca2 ? (
                                    <img
                                      src={icons.checkIcon}
                                      className="checkiconinmoda"
                                      alt=""
                                    />
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="checkbox-wrapper">
            <label
              htmlFor="1"
              className="checkbox-label"
              onClick={checkTermsVersion}
            >
              {changeTerms && (
                <img src={icons.unselect} alt="logo" className="pb-1" />
              )}
            </label>

            <label htmlFor="1" className="checkbox-text-label">
              I accept the
              <Link
                to={route.TERMS_AND_CONDITIONS}
                className="checkbox-link"
                data-bs-toggle="modal"
                data-bs-target="#terms"
              >
                Terms and Conditions
              </Link>
            </label>
          </div>
          <Button
            className="me-auto ms-auto"
            disabled={!isValid}
            onClick={handleContinue}
            style={{ marginTop: "127px" }}
            loading={isLoading}
          >
            Continue
          </Button>
        </div>
        <FooterOfWeb />
      </div>
    </>
  );
};

export default SignUp;
