import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import { pendingRequestApi } from "../api/pendingRequest/pendingRequestApi";
import { PendingRequestType } from "../api/pendingRequest/pendingRequestSchema";
import CloseNav from "../components/CloseNav";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { SkeletonList } from "../components/SkeletonList";
import { icons } from "../utils/constants/icons";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { screenSize } from "../utils/constants/screenSizes";
import { statusIcons } from "../utils/constants/status";
import { useSize } from "../utils/hooks/useSize";
import { TabTitle } from "../utils/pagetitle";
import { PendingRequestConfirmation } from "./Popups/PendingRequestConfirmation";

const PendingRequest: FC = () => {
  TabTitle("Digital Nugget | Pending Requests ");

  const history = useHistory();
  const isMobile = useSize(screenSize.MOBILE);

  const { data: pendingRequests } = useQuery(
    [queryNames.PENDING_REQUEST],
    pendingRequestApi.getPendingRequests
  );

  const { data: currentBalance } = useQuery(
    [queryNames.BALANCE],
    balanceApi.getState
  );

  const [showPendingRequestConfirmation, setShowPendingRequestConfirmation] =
    useState(false);

  const [requestBalance, setRequestBalance] = useState({
    amount: 0.0,
    currency: "",
    insufficientBalance: 0,
  });
  const [paymentRequest, setPaymentRequest] =
    useState<PendingRequestType | null>(null);

  const transactionButton = (): void => {
    history.push(route.TRANSACTIONS);
  };

  const handleRequestRow = (row: PendingRequestType): void => {
    const rowCryptoCurrency = row?.cryptoCurrency;
    const rowBalance = currentBalance?.cryptos.filter(
      (row) => row.symbol === rowCryptoCurrency
    );

    if (rowBalance && rowBalance.length > 0) {
      setRequestBalance({
        ...requestBalance,
        amount: Number(rowBalance[0]?.cryptoAmount.toFixed(8)),
        currency: rowCryptoCurrency,
        insufficientBalance:
          Number(row?.cryptoAmount) > rowBalance[0]?.cryptoAmount
            ? Number(row?.cryptoAmount)
            : 0,
      });
    }
    setPaymentRequest(row);
    setShowPendingRequestConfirmation(true);
  };

  return (
    <div>
      <div className="content">
        <Sidebar activeRoute={route.PENDING_REQUEST} />
        <div className="tab-content logged-content">
          <div className="tab-content__wrapper">
            {isMobile ? (
              <div className="tittle_position">
                <div className="settings_tittle">
                  <div className="button_group">
                    <button className="but1">Pending Requests</button>
                    <button className="but2" onClick={transactionButton}>
                      Transactions
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="tab-content__header">
                <div className="tab-content__header-text">
                  <CloseNav title={"Pending Requests"} />
                </div>
              </div>
            )}
            <div
              id="pending-requests-items"
              className="walet__items walet__items--transactions"
            >
              {!currentBalance || !pendingRequests ? (
                <SkeletonList number={3} />
              ) : pendingRequests?.length > 0 ? (
                pendingRequests?.map((prendingRequest, index: number) => {
                  return (
                    <div
                      className="walet__item"
                      onClick={(): void => handleRequestRow(prendingRequest)}
                      key={index}
                    >
                      <div className="walet__item-left">
                        <div className="transactions__icon">
                          <img
                            src={statusIcons[prendingRequest.status]}
                            alt="status"
                          />
                        </div>
                        <div className="walet__item-content">
                          <div className="walet__item-title">
                            {prendingRequest.description}
                          </div>
                          <div className="walet__item-text">
                            {prendingRequest.createdAt
                              ? format(
                                  new Date(prendingRequest.createdAt),
                                  "dd MMM yyyy"
                                )
                              : ""}
                          </div>
                        </div>
                      </div>
                      <div className="walet__item-value">
                        {Number(prendingRequest.cryptoAmount).toString()}{" "}
                        {prendingRequest.cryptoCurrency}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="empty-data">
                  <img
                    src={icons.pendingRequest}
                    height={80}
                    width={80}
                    alt="empty-data"
                  />
                  <p className="empty-data-text">
                    There are no pending requests
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <PendingRequestConfirmation
          show={showPendingRequestConfirmation}
          setShow={setShowPendingRequestConfirmation}
          paymentRequest={paymentRequest}
          setPaymentRequest={setPaymentRequest}
          requestBalance={requestBalance}
        />
      </div>
      <Footer active2={"active"} />
    </div>
  );
};

export default PendingRequest;
