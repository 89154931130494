import { useQuery } from "@tanstack/react-query";
import { FC, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { ExchangeResponse } from "../api/balance/balanceSchema";
import { userApi } from "../api/user/userApi";
import Sidebar from "../components/Sidebar";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { TransactionStatus } from "./TransactionStatus";

export const SellSuccess: FC = () => {
  const history = useHistory();
  const sellData = history.location.state as ExchangeResponse;

  const { data: user } = useQuery([queryNames.USER], userApi.getUserData);

  const handleFinish = (): void => {
    history.push(route.WALLET);
  };

  useLayoutEffect(() => {
    if (!sellData?.cryptoAmount) {
      handleFinish();
    }
  }, []);

  return (
    <div className="content">
      <Sidebar activeRoute={route.WALLET} />
      <div className="tab-content">
        <TransactionStatus
          title="Withdrawal Successful"
          text={`${sellData?.fiatAmount} ${sellData?.fiatCurrency}`}
          subText={`has been credited to your card ending in ${user?.card?.lastDigits}`}
          button={{
            text: "Finish",
            onClick: handleFinish,
          }}
        />
      </div>
    </div>
  );
};
