import * as classNames from "classnames";
import { FC, HTMLProps, MouseEvent, PropsWithChildren } from "react";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";

type ButtonProps = {
  disabled?: boolean;
  className?: string;
  id?: string;
  loading?: boolean;
  variaint?: "orange" | "transparent";
  type?: "button" | "submit" | "reset";
  link?: string;
};

export const Button: FC<
  PropsWithChildren<ButtonProps & HTMLProps<HTMLButtonElement>>
> = ({
  disabled,
  className,
  children,
  onClick,
  id,
  loading,
  style,
  variaint = "orange",
  type = "button",
  link,
}) => {
  const history = useHistory();

  const handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    onClick?.(e);

    if (link) {
      history.push(link);
    }
  };

  return (
    <button
      type={type}
      style={style}
      id={id}
      onClick={handleClick}
      className={classNames("button fill", variaint, className, { disabled })}
    >
      {loading ? <Loader /> : children}
    </button>
  );
};
