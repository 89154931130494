import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { userApi } from "../api/user/userApi";
import CloseNav from "../components/CloseNav";
import Sidebar from "../components/Sidebar";
import Spiner from "../components/Spinner";
import { icons } from "../utils/constants/icons";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { verivicationStatus } from "../utils/constants/verificationStatus";
import { TabTitle } from "../utils/pagetitle";
import { authApi } from "api/auth/authApi";
import { CurrencyDropdown } from "components/CurrencyDropdown";
import { balanceApi } from "api/balance/balanceApi";
import { Form } from "react-bootstrap";

const Profile: FC = () => {
  TabTitle("Digital Nugget | Profile");

  const history = useHistory();
  const { isLoading, data: user } = useQuery(
    [queryNames.USER],
    userApi.getUserData
  );

  const [selectedCurrencies, setSelectedCurrencies] = useState({
    currency: "USD",
    currencyValue: "",
    crypto: "AU",
    cryptoValue: "",
  });

  const editProfile = (link: string): void => {
    history.push(`/${link}`);
  };

  const startVerification = async () => {
    history.push(route.VERIFICATION);
  };

  const [showEditUserCurrency, setShowEditUserCurrency] =
    useState<boolean>(false);
  const editCurrency = () => {
    setShowEditUserCurrency(true);
  };

  const queryCLient = useQueryClient();

  const { mutate: signtOutMutation } = useMutation(authApi.signOut, {
    onSuccess: () => {
      localStorage.clear();
      sessionStorage.clear();
      queryCLient.clear();
      history.push(route.HOME);
    },
  });

  const logOut = (): void => {
    signtOutMutation();
  };

  const { data: currencies } = useQuery(
    [queryNames.CURRENCIES],
    balanceApi.getCurrencies
  );

  const selectedFiat = currencies?.fiatCurrencies?.find(
    ({ symbol }) => symbol === selectedCurrencies.currency
  );
  const selectedFiatSymbol = selectedFiat?.symbol || "";
  return (
    <>
      {showEditUserCurrency && (
        <div className="edit_user_currency">
          <div className="edit_user_currency_header">
            <p className="edit_user_currency_title">Change Currency</p>
            <img
              src={icons.close_icon_modal}
              className="edit_user_currency_cancel"
              onClick={() => setShowEditUserCurrency(false)}
            />
          </div>
          <div className="currency_description_container">
            <p className="currency_description">Currency</p>
            <Form.Select>
              <option>{selectedFiatSymbol}</option>
              {currencies?.fiatCurrencies?.map(({ symbol, id }) => (
                <option key={id}>{symbol}</option>
              ))}
            </Form.Select>
          </div>
          <div className="edit_user_currency_buttons">
            <img
              src={icons.cancel_icon}
              alt="Cancel"
              className="edit_user_currency_cancel"
              onClick={() => setShowEditUserCurrency(false)}
            />
            <img
              src={icons.save_icon}
              alt="Save"
              className="edit_user_currency_cancel"
              onClick={() => setShowEditUserCurrency(false)}
            />
          </div>
        </div>
      )}
      <div
        className={
          showEditUserCurrency
            ? "content content_profile_page content_profile_page_background"
            : "content content_profile_page"
        }
      >
        <Sidebar activeRoute={route.SETTINGS} />
        <div className="tab-content hideScrollbar logged-content">
          {isLoading ? (
            <Spiner />
          ) : (
            <div className="tab-content__wrapper">
              <CloseNav title={"My Account"} route={route.SETTINGS} />
              <div className="profile-items">
                <div
                  className={
                    showEditUserCurrency
                      ? "profile-item profile-item-inactive"
                      : "profile-item"
                  }
                >
                  <div className="profile-item__title">
                    {/* <p className="tittle-1">
                    <strong>Name </strong>
                  </p> */}
                    <strong>
                      {user?.firstName} {user?.lastName}
                    </strong>
                  </div>
                </div>
                <div
                  className={
                    showEditUserCurrency
                      ? "profile-item profile-item-inactive"
                      : "profile-item"
                  }
                  style={{ cursor: "pointer" }}
                  onClick={(): void => editProfile("add-number")}
                >
                  <div className="vector1">
                    <p className="tittle-1">
                      <strong>Mobile Number </strong>
                    </p>

                    {user?.phone}
                  </div>
                  {/* <div className="vvector1">
                  <img
                    src={icons.edit_icone}
                    style={{ cursor: "pointer" }}
                    alt=""
                  />
                </div> */}
                </div>
                {/* <div
                className="profile-item"
                style={{ cursor: "pointer" }}
                onClick={(): void => editProfile("add-email")}
              >
                <div className="vector1">
                  <p className="tittle-1">
                    <strong>Email Address </strong>
                  </p>
                  {user?.email}
                </div>
                <div className="vvector1">
                  <img
                    src={icons.edit_icone}
                    style={{ cursor: "pointer" }}
                    alt=""
                  />
                </div>
              </div> */}
                <div
                  className={
                    showEditUserCurrency
                      ? "profile-item profile-item-inactive"
                      : "profile-item"
                  }
                >
                  <div className="profile-item__title">
                    <strong> Address </strong> <br />
                    {user?.address}
                  </div>
                </div>
                <div
                  className={
                    showEditUserCurrency
                      ? "profile-item profile-item-inactive"
                      : "profile-item"
                  }
                >
                  <div className="profile-item__title profile_item__title">
                    <strong> Date of Birth </strong>
                    <br />
                    {user?.dateOfBirth}
                  </div>
                </div>
                <div
                  className={
                    showEditUserCurrency
                      ? "profile-item profile_currency_container profile-item-inactive"
                      : "profile-item profile_currency_container"
                  }
                >
                  <div className="profile-item__title">
                    <strong> Currency </strong>

                    <br />
                    {user?.currency}
                  </div>
                  <img
                    src={icons.edit_simple_icon}
                    alt=""
                    className="editIcon"
                    onClick={(): void => editCurrency()}
                  />
                </div>
                <div
                  className={
                    showEditUserCurrency
                      ? "profile-item profile-item-inactive"
                      : "profile-item"
                  }
                >
                  <div className="profile-item__title profile_item__title">
                    <strong> Update Customer Information </strong>
                    <br />
                    For any changes to your account details, please email:
                    &nbsp;
                    <a className="kycEmail" href="mailto: kyc@digitalnugget.io">
                      kyc@digitalnugget.io
                    </a>
                  </div>
                </div>
                <div
                  className={
                    showEditUserCurrency
                      ? "profile-item profile-item-inactive"
                      : "profile-item"
                  }
                >
                  <div className="profile-item__title profile_item__title profile_logout_container">
                    <strong> Sign out </strong>
                    <img
                      src={icons.logout_logo}
                      alt=""
                      className="editIcon"
                      onClick={logOut}
                    />
                  </div>
                </div>

                {/* <div
                className="profile-item"
                style={{ cursor: "pointer" }}
                onClick={(): void => editProfile("add-card")}
              >
                <div className="vector1 ">
                  <p className="cardTittle">Add Card</p>
                  <span className="upperCaseText">
                    {user?.card?.brand &&
                      user?.card.lastDigits &&
                      user?.card.brand + " * " + user?.card.lastDigits}
                  </span>
                </div>
                <div className="vvector1">
                  <img
                    src={icons.nextArrow}
                    style={{ cursor: "pointer" }}
                    alt=""
                  />
                </div>
              </div> */}
                {/* <div
                className="profile-item"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (user?.kycStatus !== verivicationStatus.VERIFIED) {
                    startVerification();
                  }
                }}
              >
                <div className="vector1">
                  <p className="tittle-1">
                    <strong> Proof of Identity </strong>
                  </p>
                  {user?.kycStatus === verivicationStatus.UNVERIFIED &&
                    "Your KYC is not verified"}
                  {user?.kycStatus === verivicationStatus.VERIFIED &&
                    "Account is verified"}
                  {user?.kycStatus === verivicationStatus.PENDING &&
                    "Waiting for approval"}
                </div>
                {user?.kycStatus !== verivicationStatus.VERIFIED && (
                  <div className="vvector1">
                    <img
                      src={icons.edit_icone}
                      style={{ cursor: "pointer" }}
                      alt=""
                    />
                  </div>
                )}
              </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
