import { FC } from "react";

type MaxInputValueProps = {
  value?: number;
  currency?: string;
  onClick?: (value: number) => void;
};

export const MaxInputValue: FC<MaxInputValueProps> = ({
  value = 0,
  currency,
  onClick,
}) => {
  const handleClick = (): void => {
    onClick?.(value);
  };

  return (
    <div className="input-max-value" onClick={handleClick}>
      Max {value} {currency}
    </div>
  );
};
