import { ChangeEvent, FC, KeyboardEvent, useRef } from "react";

type OtpInputProps = {
  otp: string[];
  setOtp: (code: string[]) => void;
  onSubmit?: () => void;
};

const goBackKeys = ["Backspace", "Delete"];

const OtpInput: FC<OtpInputProps> = ({ otp, setOtp, onSubmit }) => {
  const formRef = useRef<HTMLFormElement | null>(null);

  const goLeft = (index: number): void => {
    const next = index - 1;

    if (next > -1 && formRef.current) {
      (formRef.current.elements[next] as HTMLInputElement).focus();
    }
  };

  const goRight = (index: number): void => {
    const next = index + 1;

    if (next < 6 && formRef.current) {
      (formRef.current.elements[next] as HTMLInputElement).focus();
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ): void => {
    const { value } = e.target;

    if (isNaN(Number(value)) || value === " ") {
      return;
    }

    const changedOtp = [...otp];

    changedOtp[index] = value;

    setOtp(changedOtp);

    if (
      (e.nativeEvent as InputEvent).inputType === "deleteContentBackward" &&
      value
    ) {
      goLeft(index);
    }

    if ((e.nativeEvent as InputEvent).inputType === "insertText") {
      goRight(index);
    }
  };

  const handleKeyDown = (
    e: KeyboardEvent<HTMLInputElement>,
    index: number
  ): void => {
    const target = e.target as HTMLInputElement;

    if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
      return;
    }

    if (!otp[index] && goBackKeys.includes(e.key)) {
      goLeft(index);
    }

    if (
      otp[index] &&
      !goBackKeys.includes(e.key) &&
      !isNaN(Number(target.value))
    ) {
      goRight(index);
    }

    if (e.key === "Enter") {
      onSubmit?.();
    }
  };

  return (
    <form ref={formRef}>
      <div className="otp-container">
        {otp.map((_, index) => {
          return (
            <input
              key={index}
              type="text"
              autoComplete="off"
              autoFocus={index === 0}
              pattern="[0-9]*"
              inputMode="numeric"
              className="otp-input"
              value={otp[index]}
              onChange={(e) => {
                handleChange(e, index);
              }}
              onKeyDown={(e) => {
                handleKeyDown(e, index);
              }}
              maxLength={1}
            />
          );
        })}
      </div>
    </form>
  );
};

export default OtpInput;
