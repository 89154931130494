import { FC } from "react";

type SkeletonListProps = {
  number: number;
};

export const SkeletonList: FC<SkeletonListProps> = ({ number }) => {
  return (
    <>
      {Array.from(Array(number).keys()).map((id) => {
        return (
          <div className="walet__item" key={id}>
            <div className="walet__item-left">
              <div className="transactions__icon">
                <div className="loading-skeleton image_menu_skeleton" />
              </div>
              <div className="walet__item-content">
                <div className=" skeleton-text balanaceSkeleton"></div>
                <div className=" skeleton-text amountSkeleton"></div>
              </div>
            </div>
            <div className="walet__item-value">
              <div className=" skeleton-text balanaceSkeleton"></div>
            </div>
          </div>
        );
      })}
    </>
  );
};
