import { FC, useState, useEffect } from "react";
import { icons } from "../../utils/constants/icons";

import data from "./faq-data";
import addional_data from "./faq-additional-data";

import "./style.css";

interface YourItemType {
  id: number;
  title: string;
  description: string;
  open: boolean;
}
interface YourAdditionalType {
  id: number;
  title: string;
  description: string;
  open: boolean;
}

const Faq: FC = () => {
  const [open, setOpen] = useState<YourItemType[]>([]);
  const [openAdditionalContent, setOpenAdditionalContent] =
    useState<boolean>(false);
  const [openAdditional, setOpenAdditional] = useState<YourAdditionalType[]>(
    []
  );

  useEffect(() => {
    const newOpenState = data.map((item: YourItemType) => ({
      ...item,
      open: false,
    }));
    setOpen(newOpenState);

    const newOpenStateAdditional = addional_data.map(
      (item: YourAdditionalType) => ({
        ...item,
        open: false,
      })
    );
    setOpenAdditional(newOpenStateAdditional);
  }, []);

  const toggleOpen = (i: number) => {
    const newOpenState: YourItemType[] = open.map((item, index) =>
      index == i ? { ...item, open: !item.open } : { ...item, open: false }
    );

    setOpen(newOpenState);

    const newOpenAdditionalState: YourAdditionalType[] = openAdditional.map(
      (item) => ({
        ...item,
        open: false,
      })
    );
    setOpenAdditional(newOpenAdditionalState);
  };

  const toggleAddionalOpen = (i: number) => {
    const newOpenAdditional: YourAdditionalType[] = openAdditional.map(
      (item, index) =>
        index == i ? { ...item, open: !item.open } : { ...item, open: false }
    );
    setOpenAdditional(newOpenAdditional);
    const newOpenState: YourItemType[] = open.map((item) => ({
      ...item,
      open: false,
    }));
    setOpen(newOpenState);
  };

  return (
    <div className="home-faq home-section" id="faq">
      <div className="home-container">
        <h2 className="home-title">Frequently asked questions</h2>

        <p className="home-p">
          Everything you need to know about buying gold with Digital Nugget
        </p>

        <div className="faq_container grid_container">
          {open.map((item, index) => (
            <div
              key={index}
              className={`faq_item grid_item ${item.open ? "active" : ""}`}
              onClick={() => toggleOpen(index)}
            >
              <div className="title_container">
                <h3>{" " + item.title}</h3>

                {item.open ? (
                  <img src={icons.minus} />
                ) : (
                  <img src={icons.plus} />
                )}
              </div>

              {item.open && <p>{item.description}</p>}
            </div>
          ))}
          {openAdditionalContent &&
            openAdditional.map((item, index) => (
              <div
                key={index}
                className={`faq_item grid_item ${item.open ? "active" : ""}`}
                onClick={() => toggleAddionalOpen(index)}
              >
                <div className="title_container">
                  <h3>{" " + item.title}</h3>

                  {item.open ? (
                    <img src={icons.minus} />
                  ) : (
                    <img src={icons.plus} />
                  )}
                </div>

                {item.open && <p>{item.description}</p>}
              </div>
            ))}
          <div style={{ display: "flex", justifyContent: "center" }}>
            <button
              onClick={() => {
                // document
                //   .querySelector(".reversedFill")
                //   ?.classList.remove("reversedFill");
                setOpenAdditionalContent(!openAdditionalContent);
              }}
              // onMouseEnter={() => {
              //   document
              //     .querySelector(".reversedFill")
              //     ?.classList.add("reversedFill");
              // }}
              className="contact_us_btn reversedFill"
            >
              {openAdditionalContent ? "Show Less" : "Show More"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
