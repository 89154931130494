import { AxiosInstance } from "axios";
import { endpoints } from "../../utils/constants/endpoints";
import { axiosInstance } from "../axiosInstance";
import {
  CheckUserExistResponse,
  CountryCodeResponse,
  GlobalSettingsResponse,
  SendFeedbackPayload,
  UpdateEmailPayload,
  UpdateEmailResponse,
  UpdateNotificationSettengsPayload,
  UpdateNotificationSettengsResponse,
  UpdatePhonePayload,
  UpdatePhoneResponse,
  UserDataPayload,
  UserDataResponse,
} from "./userSchema";

class UserApi {
  #requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.#requestInstance = axiosInstance;
  }

  updatePhone = async (
    phoneNumber: UpdatePhonePayload
  ): Promise<UpdatePhoneResponse> => {
    return this.#requestInstance.put(endpoints.UPDATE_NUMBER, phoneNumber);
  };

  updateEmail = async (
    email: UpdateEmailPayload
  ): Promise<UpdateEmailResponse> => {
    return this.#requestInstance.put(endpoints.UPDATE_EMAIL, email);
  };

  updateNotificationSettings = async (
    settings: UpdateNotificationSettengsPayload
  ): Promise<UpdateNotificationSettengsResponse> => {
    return this.#requestInstance.put(endpoints.NOTIFICATION, settings);
  };

  sendFeedback = async (feedback: SendFeedbackPayload): Promise<void> => {
    return this.#requestInstance.post(endpoints.FEEFBACK, feedback);
  };

  getUserData = async (): Promise<UserDataResponse> => {
    return this.#requestInstance.get(endpoints.USER_DATA);
  };

  updateUserData = async (
    userData: UserDataPayload
  ): Promise<UserDataResponse> => {
    return this.#requestInstance.put(endpoints.UPDATE_USER_DATA, userData);
  };

  getCountryCode = async (): Promise<CountryCodeResponse> => {
    return this.#requestInstance.get(endpoints.GET_COUNTRY);
  };

  addFcmToken = async (token: string) => {
    return this.#requestInstance.post(endpoints.ADD_FCM_TOKEN, {
      token,
    });
  };

  checkUserExist = async (
    phoneNumber: string
  ): Promise<CheckUserExistResponse> => {
    return this.#requestInstance.post(endpoints.CHECK_USER_EXIST, {
      phoneNumber,
    });
  };

  getGlobalSettings = async (): Promise<GlobalSettingsResponse> => {
    return this.#requestInstance.get(endpoints.GLOBAL_SETTINGS);
  };
}

export const userApi = new UserApi(axiosInstance);
