import { useQuery } from "@tanstack/react-query";
import CloseNav from "components/CloseNav";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import { CurrencyDropdown } from "../components/CurrencyDropdown";
import { NumberInput } from "../components/NumberInput";
import Sidebar from "../components/Sidebar";
import Spiner from "../components/Spinner";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { verivicationStatus } from "../utils/constants/verificationStatus";
import { alertToast } from "../utils/helpers/alert";
import { limitsPlaceholder } from "../utils/helpers/limitsPlaceholder";
import { useDebounce } from "../utils/hooks/useDebounce";
import { TabTitle } from "../utils/pagetitle";

const Buy: FC = () => {
  TabTitle("Digital Nugget | Buy");
  const history = useHistory();

  const [selectedCurrencies, setSelectedCurrencies] = useState({
    currency: "USD",
    currencyValue: "",
    crypto: "AU",
    cryptoValue: "",
  });

  const debounce = useDebounce(selectedCurrencies, 100);

  const { data: user } = useQuery([queryNames.USER], userApi.getUserData);

  const { data: globalSettings } = useQuery(
    [queryNames.GLOBAL_SETTINGS],
    userApi.getGlobalSettings
  );

  const { isLoading: currenciesIsLoading, data: currencies } = useQuery(
    [queryNames.CURRENCIES],
    balanceApi.getCurrencies
  );

  const { data: exchangeData, isFetching } = useQuery(
    [queryNames.EXCHANGE_VALUE, debounce],
    () => {
      const payload = {
        from: {
          currency: selectedCurrencies.currency,
          type: "fiat",
          amount: +selectedCurrencies.currencyValue,
        },
        to: {
          currency: selectedCurrencies.crypto,
          type: "crypto",
        },
      };

      return balanceApi.getExchangeRate(payload);
    },
    {
      enabled: !!debounce.currencyValue,
    }
  );

  const handleBuy = (): void => {
    if (user?.kycStatus !== verivicationStatus.VERIFIED) {
      alertToast({
        message: "Transaction cannot be completed without KYC verification",
      });

      return;
    }

    if (
      globalSettings &&
      +selectedCurrencies.currencyValue < globalSettings?.minDeposit
    ) {
      return;
    }
    console.log("selectedCurrencies", selectedCurrencies);
    console.log("exchangeData", exchangeData);

    if (selectedCurrencies.currencyValue && exchangeData?.rates) {
      localStorage.setItem(
        localStorageVariable.EXCHANGE,
        JSON.stringify(selectedCurrencies)
      );
      history.push(route.BUY_PAYMENT);
    }
  };

  const valueOfAu = selectedCurrencies?.crypto === "AU" ? "XAU" : "";
  const value = exchangeData?.rates?.[valueOfAu];

  const valueOfAuFromMoney = +selectedCurrencies?.currencyValue * value;

  return (
    <div>
      <div className="content">
        <Sidebar activeRoute={route.WALLET} />

        <div className="tab-content logged-content">
          {currenciesIsLoading ? (
            <Spiner />
          ) : (
            <div className="tab-content__wrapper">
              <div className="body_position">
                <div className="converter_body">
                  <CloseNav title={"Buy"} route={route.WALLET} />
                  <div className="converter__wrapper converter__wrapper--buy">
                    <div className="converter__item">
                      <h3 className="h3">I want to spend</h3>
                      <div className="converter__input-wrapper">
                        <NumberInput
                          value={selectedCurrencies.currencyValue}
                          onChange={(value: string): void => {
                            setSelectedCurrencies({
                              ...selectedCurrencies,
                              currencyValue: value,
                            });
                          }}
                          max={globalSettings?.maxDeposit}
                          placeholder={limitsPlaceholder(
                            globalSettings?.minDeposit,
                            globalSettings?.maxDeposit
                          )}
                          onSubmit={handleBuy}
                        />
                        <CurrencyDropdown
                          currencies={currencies?.fiatCurrencies}
                          onClick={(row) => {
                            setSelectedCurrencies({
                              ...selectedCurrencies,
                              currency: row.symbol,
                            });
                          }}
                          selectedCurrency={currencies?.fiatCurrencies?.find(
                            ({ symbol }) =>
                              symbol === selectedCurrencies.currency
                          )}
                          id={1}
                        />
                      </div>
                    </div>
                    <div className="converter__item">
                      <h3 className="h3">You will recieve</h3>
                      <div className="converter__input-wrapper">
                        <div className="converter__input">
                          {isFetching && <Spiner sell={true} />}
                          {!isFetching && valueOfAuFromMoney > 0 && (
                            <>
                              {valueOfAuFromMoney > 0
                                ? valueOfAuFromMoney.toFixed(4)
                                : ""}
                            </>
                          )}
                          {!isFetching && !valueOfAuFromMoney && (
                            <span className="fontResetHome">0.00</span>
                          )}
                        </div>
                        <CurrencyDropdown
                          currencies={currencies?.cryptoCurrencies}
                          onClick={(row) => {
                            setSelectedCurrencies({
                              ...selectedCurrencies,
                              crypto: row.symbol,
                            });
                          }}
                          selectedCurrency={currencies?.cryptoCurrencies?.find(
                            ({ symbol }) => symbol === selectedCurrencies.crypto
                          )}
                          id={2}
                          oneItemOnly={true}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <Button
                      disabled={
                        globalSettings &&
                        +selectedCurrencies.currencyValue <
                          globalSettings?.minDeposit
                      }
                      onClick={handleBuy}
                      className="center"
                    >
                      Buy
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Buy;
