import { FC } from "react";
import CloseNav from "../components/CloseNav";
// import Sidebar from "../components/Sidebar";
import { route } from "../utils/constants/route";
import { TabTitle } from "../utils/pagetitle";
import ReactHtmlParser from "react-html-parser";
import { _termsAndConditions } from "utils/constants/termsAndCondictions";

const TermAndConditions: FC = () => {
  TabTitle("Digital Nugget | Terms and Conditions");
  return (
    <div className="content stickyContent">
      {/* <Sidebar activeRoute={route.SETTINGS} /> */}
      <div
        className="tab-content hideScrollbar overflowContent"
        id="scrollable"
      >
        <div className="termBody">
          <div className="privacy-header">
            <CloseNav title={"Terms and Conditions"} route={route.SETTINGS} />
          </div>
          <div className="policy-content">
            {ReactHtmlParser(_termsAndConditions)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermAndConditions;
