import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { balanceApi } from "../../api/balance/balanceApi";
import { animationDuration, OffCanvas } from "../../components/OffCanvas";
import { queryNames } from "../../utils/constants/queryNames";
import { succesToast } from "../../utils/helpers/alert";
import { ConfirmPopup } from "./ConfirmPopup";
import { SuccessPopup } from "./SuccessPopup";

type TransferData = {
  currency: string;
  amount: string;
  phoneNumber: string;
  walletAddress: string;
  currentBalance: number;
};

type TransferConfirmationProps = {
  setShowTransferConfirmation: (isShow: boolean) => void;
  showTransferConfirmation: boolean;
  transferData: TransferData;
  onSuccess: () => void;
};

export const TransferConfirmation: FC<TransferConfirmationProps> = ({
  setShowTransferConfirmation,
  showTransferConfirmation,
  transferData,
  onSuccess,
}) => {
  const quryClient = useQueryClient();
  const [isComplete, setIsComplete] = useState(false);
  const transferTo = transferData.phoneNumber || transferData.walletAddress;

  const { isLoading, mutate: transferMutation } = useMutation(
    balanceApi.transfer,
    {
      onSuccess: () => {
        setIsComplete(true);
        quryClient.invalidateQueries([queryNames.WALLET]);
      },
    }
  );

  const close = (): void => {
    setShowTransferConfirmation(false);

    setTimeout(() => {
      setIsComplete(false);
    }, animationDuration);
  };

  const onSuccessClose = (): void => {
    close();
    succesToast({
      message: `${transferData.amount} ${transferData.currency} transfer to ${transferTo}`,
    });
    onSuccess();
  };

  const handleAccept = (): void => {
    if (transferData.phoneNumber) {
      transferMutation({
        from: {
          currency: transferData.currency,
          type: "crypto",
          amount: +transferData.amount,
        },
        phoneNumber: transferData.phoneNumber,
      });
    }

    if (transferData.walletAddress) {
      transferMutation({
        from: {
          currency: transferData.currency,
          type: "crypto",
          amount: +transferData.amount,
        },
        walletAddress: transferData.walletAddress,
      });
    }
  };

  return (
    <OffCanvas
      show={showTransferConfirmation}
      setShow={setShowTransferConfirmation}
      onClose={isComplete ? onSuccessClose : close}
      ignoreClickOutsideList={["#continue-button"]}
      onSubmit={isComplete ? onSuccessClose : handleAccept}
    >
      {!isComplete ? (
        <ConfirmPopup
          close={close}
          isLoading={isLoading}
          onAccept={handleAccept}
          onDecline={close}
          title="Transfer Request"
          actionType="accept"
          alertInfo="This transaction cannot be undone. Please ensure the details are
          correct before clicking accept."
        >
          <div className="current-balance">
            <span>Current Balance</span>
            <span className="f-600">
              {transferData.currentBalance} {transferData.currency}
            </span>
          </div>
          <div className="payment-request-wrapper">
            <div className="payment-request-value">
              <div className="payment-request-amount">
                {transferData.amount}
              </div>
              <div className="payment-request-crypto">
                {transferData.currency}
              </div>
            </div>
            <div className="payment-request-title">
              will be transferred to <br /> {transferTo}
            </div>
          </div>
        </ConfirmPopup>
      ) : (
        <SuccessPopup close={onSuccessClose} title="Transfer Sent">
          <div className="transfer-sent-balance">
            <div className="payment-request-amount">{transferData.amount}</div>
            <div className="payment-request-crypto">
              {transferData.currency}
            </div>
          </div>
          <div className="transfer-text">was transferred to</div>
          <div className="transfer-text">{transferTo}</div>
          <div className="modal-buttons parent"></div>
        </SuccessPopup>
      )}
    </OffCanvas>
  );
};
