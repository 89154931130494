export const endpoints = {
  SIGN_UP: "/v1/regular-sign-up",
  SIGN_OUT: "/v1/sign-out",
  COUNTRIES: "/v1/countries",
  REFRESH_TOKEN: "/v1/refresh",
  OTP_VERIFY: "/v1/activate-with-otp?otp=",
  OTP_SINGIN: "/v1/sign-in",
  POLICY: "/v1/policy?country=ALL",
  TERMS: "/v1/term?country=ALL&version=1.0",
  UPDATE_NUMBER: "/v1/users/update-phone",
  UPDATE_EMAIL: "/v1/users/update-email",
  FEEFBACK: "/v1/users/send-feedback",
  GET_STATE: "/v1/balances/current-state",
  USER_DATA: "/v1/users/me",
  VERIFY_NUMBER: "/v1/users/verify-phone",
  // EXCHANGE_RATE: "/v1/balances/exchange-rate",
  EXCHANGE_RATE: "https://api.metalpriceapi.com/v1/latest",
  VERIFY_EMAIL: "/v1/users/verify-email?token=",
  TRANSACTIONS: "/v1/balances/transactions",
  NOTIFICATION: "/v1/users/notifications-settings",
  BUY_CRYPTO: "/v1/balances/buy-crypto",
  SELL_CRYPTO: "/v1/balances/sell-crypto",
  ADD_CARD: "/v1/balances/add-card",
  VERIFY_OTP: "/v1/verify-otp",
  UPDATE_USER_DATA: "/v1/users/update-data",
  GET_COUNTRY: "/v1/users/country-code",
  GET_SUMSUB_ACCESS_TOKEN: "/v1/sumsub/access-token",
  BALANCE_TOP_UP: "/v1/balances/top-up",
  GET_ALL_CURRENCIES: "/v1/balances/currencies/all",
  PENDING_REQUESTS: "/v1/pending-requests",
  CONVERT_CRYPTO: "/v1/balances/convert-crypto",
  TRANSFER: "/v1/balances/transfer",
  ADD_FCM_TOKEN: "/v1/users/add-fcm-token",
  CHECK_USER_EXIST: "/v1/users/check-user-exists",
  GLOBAL_SETTINGS: "/v1/users/global-settings",
  PAYMENT_STATUS: "/v1/balances/transaction",
} as const;
