import * as classNames from "classnames";
import { FC, KeyboardEvent, PropsWithChildren, useEffect, useRef } from "react";
import { useClickOutside } from "../utils/hooks/useClickOutside";

export const animationDuration = 300;

type OffCanvasProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  onClose: () => void;
  onSubmit?: () => void;
  ignoreClickOutsideList?: string[];
};

export const OffCanvas: FC<PropsWithChildren<OffCanvasProps>> = ({
  show,
  setShow,
  onClose,
  onSubmit,
  children,
  ignoreClickOutsideList,
}) => {
  const offsetRef = useRef<HTMLDivElement>(null);

  const close = () => {
    setShow(false);
    onClose?.();
  };

  useClickOutside<HTMLDivElement>(offsetRef, close, ignoreClickOutsideList);

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.code === "Escape") {
      close();
    }

    if (e.code === "Enter") {
      onSubmit?.();
    }
  };

  useEffect(() => {
    if (show) {
      offsetRef.current?.focus();
    }
  }, [show]);

  return (
    <>
      <div
        className={classNames("offcanvas offcanvas-end", { show })}
        tabIndex={-1}
        ref={offsetRef}
        onKeyDown={handleKeyDown}
      >
        <div className="offcanvas-wrapper">{children}</div>
      </div>
      <div className={classNames("modal-backdrop fade", { show })}></div>
    </>
  );
};
