import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { userApi } from "../api/user/userApi";
import CloseNav from "../components/CloseNav";
import Sidebar from "../components/Sidebar";
import Spiner from "../components/Spinner";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { alertToast, succesToast } from "../utils/helpers/alert";
import { TabTitle } from "../utils/pagetitle";

export const Notifications: FC = () => {
  TabTitle("Digital Nugget | Notifications");

  const queryClient = useQueryClient();
  let switchKey = "";

  const { isLoading, data: user } = useQuery(
    [queryNames.USER],
    userApi.getUserData
  );
  const { mutate: updateNotifiactionSettingsMutation } = useMutation(
    userApi.updateNotificationSettings,
    {
      onSuccess: (_, notificationsSettings) => {
        succesToast({
          message: notificationsSettings[switchKey]
            ? "Notifications are enabled successfully"
            : "Notifications are disabled successfully",
        });
      },
    }
  );

  const updateNotificationSettings = (): void => {
    try {
      const transformedSettings = {
        specialOffers: !!user?.notificationsSettings?.specialOffers,
        transaction: !!user?.notificationsSettings?.transaction,
      };

      const notificationsSettings = {
        ...transformedSettings,
        [switchKey]: !transformedSettings[switchKey],
      };

      queryClient.setQueriesData([queryNames.USER], {
        ...user,
        notificationsSettings,
      });

      updateNotifiactionSettingsMutation(notificationsSettings);
    } catch {
      queryClient.setQueriesData([queryNames.USER], {
        ...user,
        notificationsSettings: user?.notificationsSettings,
      });
    }
  };

  const handleSwitch = async (key: string): Promise<void> => {
    switchKey = key;

    if (!user?.notificationsSettings || !window.Notification) {
      return;
    }

    if (
      Notification.permission === "denied" &&
      !user?.notificationsSettings[key]
    ) {
      alertToast({
        message: "Enable access to notifications in your browser",
      });

      return;
    }

    if (
      Notification.permission === "default" &&
      !user?.notificationsSettings[key]
    ) {
      const premission = await Notification.requestPermission();

      if (premission === "granted") {
        updateNotificationSettings();
      }

      return;
    }

    updateNotificationSettings();
  };

  return (
    <div className="content">
      <Sidebar activeRoute={route.SETTINGS} />
      <div className="tab-content hideScrollbar">
        <div className="tab-content__wrapper">
          {isLoading ? (
            <Spiner />
          ) : (
            <div className="header-tittle">
              <CloseNav title={"Notification"} route={route.SETTINGS} />
              <div className="body_position">
                <div className="notifications">
                  <div className="tittle1">Push Notifications</div>
                  <div className="paragraph1">
                    <div className="para1">Transactions</div>
                    <input
                      type="checkbox"
                      className="switch"
                      id="switch1"
                      checked={!!user?.notificationsSettings?.transaction}
                      onChange={(): void => {
                        handleSwitch("transaction");
                      }}
                    />
                    <label htmlFor="switch1" className="label1"></label>
                  </div>
                  <div className="paragraph1">
                    <div className="para1">Special Offers</div>
                    <input
                      type="checkbox"
                      className="switch"
                      id="switch2"
                      checked={!!user?.notificationsSettings?.specialOffers}
                      onChange={(): void => {
                        handleSwitch("specialOffers");
                      }}
                    />
                    <label htmlFor="switch2" className="label1"></label>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
