import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { authApi } from "../api/auth/authApi";
import { userApi } from "../api/user/userApi";
import {
  UpdateEmailPayload,
  UpdateEmailResponse,
} from "../api/user/userSchema";
import { Button } from "../components/Button";
import FooterOfWeb from "../components/FooterOfWeb";
import OtpInput from "../components/OtpInput";
import { ResendCode } from "../components/ResendCode";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { succesToast } from "../utils/helpers/alert";
import { useSearchParams } from "../utils/hooks/useSearchParams";
import { TabTitle } from "../utils/pagetitle";
import { icons } from "utils/constants/icons";

const OtpUpdateEmail: FC = () => {
  TabTitle("Digital Nugget | Profile");
  const history = useHistory();
  const searchParams = useSearchParams();

  const queryClient = useQueryClient();
  const otpFromServer = localStorage.getItem(localStorageVariable.OTP) || "";

  const [otp, setOtp] = useState(
    searchParams.otp.split("") || new Array(6).fill("")
  );
  const otpInputValue = otp.join("");

  const { mutate: verifyEmailMutation, isLoading } = useMutation(
    authApi.verifyEmail,
    {
      onSuccess: (data) => {
        queryClient.setQueriesData([queryNames.USER], data.userData);
        localStorage.removeItem(localStorageVariable.OTP);
        localStorage.removeItem(localStorageVariable.EMAIL);
        history.push(route.PROFILE);
        succesToast({
          message: "Email has been updated",
        });
      },
    }
  );

  const verifyCode = (): void => {
    if (otpInputValue.length !== 6) {
      return;
    }

    const payload = {
      otp: Number(otpInputValue),
    };

    verifyEmailMutation(payload);
  };

  return (
    <>
      <div className="containerr">
        <header className="header">
          <div className="header__container">
            <div className="logo">
              <Link to={route.WALLET}>
                {/* <button className="nixxeTittle">NIXXE</button> */}
                <img src={icons.digital_nugget_logo} alt="logo" />
              </Link>
            </div>
          </div>
        </header>

        <div className="verify-wrapper">
          <div className="sub-header">
            <h2 className="h2">Verify your email address</h2>
            <div className="sub-title">
              Please type the verification code sent to
              {localStorageVariable.EMAIL}
            </div>
          </div>
          <div className="otp-input-container">
            <OtpInput otp={otp} setOtp={setOtp} onSubmit={verifyCode} />
          </div>
          <ResendCode<UpdateEmailPayload, UpdateEmailResponse>
            resendCode={userApi.updateEmail}
            payload={{
              email: localStorageVariable.EMAIL || "",
            }}
            onSuccess={() => {
              setOtp(new Array(6).fill(""));
            }}
          />
          <div className="verify-footer">
            Wrong email?{" "}
            <Link to={route.ADD_EMAIL} className="verify-footer__link">
              Click here to change
            </Link>
          </div>
          <div className="verify-footer">OTP: {otpFromServer}</div>
          <div className="verify-button">
            <Button
              disabled={otpInputValue.length < 6}
              loading={isLoading}
              onClick={verifyCode}
            >
              Continue
            </Button>
          </div>
        </div>
        <FooterOfWeb />
      </div>
    </>
  );
};

export default OtpUpdateEmail;
