import * as classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { icons } from "../utils/constants/icons";
import { statusType } from "../utils/constants/status";
import { StatusType } from "../utils/types/statusType";
import { PaymentLoader } from "./PaymentLoader";

type TransactionTitleProps = {
  className?: string;
  status?: StatusType;
};

export const TransactionTitle: FC<PropsWithChildren<TransactionTitleProps>> = ({
  children,
  className = "",
  status = statusType.FULFILLED,
}) => {
  return (
    <div className={classNames("modal_title", { className })}>
      {status === statusType.FULFILLED && (
        <img
          alt="transaction-status-success"
          src={icons.completeTick}
          className="transaction-status"
        />
      )}
      {status === statusType.REJECTED && (
        <img
          alt="transaction-status-rejected"
          src={icons.rejectTick}
          className="transaction-status"
        />
      )}
      {status === statusType.PENDING && (
        <PaymentLoader className="transaction-status" />
      )}
      {children}
    </div>
  );
};
