import * as classNames from "classnames";
import { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { icons } from "../utils/constants/icons";
import { screenSize } from "../utils/constants/screenSizes";
import { useSize } from "../utils/hooks/useSize";
import ProgressBar from "./ProgressBar";

type CloseNavProps = {
  title?: string;
  close?: boolean;
  route?: string;
  progressBar?: true;
  step?: number;
  prog?: number;
  relative?: boolean;
  center?: boolean;
  nextArrow?: boolean;
};

const CloseNav: FC<CloseNavProps> = ({
  title,
  close,
  route,
  progressBar,
  step = 0,
  prog = 0,
  nextArrow,
}) => {
  const history = useHistory();
  const isMobile = useSize(screenSize.MOBILE);

  const handleBack = (): void => {
    history.goBack();
  };

  return (
    <div
      className={classNames("close-nav", {
        close,
        "progerss-bar": progressBar,
      })}
    >
      {(route || close || nextArrow || (isMobile && progressBar)) && (
        <div className="modal-header">
          {route && (
            <button className="backBtn">
              {route === "back" ? (
                <img
                  src={icons.nextArrow}
                  alt="Back"
                  className="backArrow"
                  onClick={handleBack}
                />
              ) : (
                <Link to={route}>
                  <img src={icons.nextArrow} alt="Back" className="backArrow" />
                </Link>
              )}
            </button>
          )}
          {isMobile && progressBar && (
            <ProgressBar progress={prog} step={step} />
          )}
          {close && (
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          )}
          {nextArrow && (
            <img
              src={icons.nextArrow}
              alt="Back"
              className="backArrow mb-4 pe-1"
              data-bs-dismiss="modal"
            />
          )}
        </div>
      )}
      {title && <div className="titleOfModal sticky-head">{title}</div>}
    </div>
  );
};

export default CloseNav;
