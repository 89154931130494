import * as classNames from "classnames";
import { FC } from "react";
import { Link } from "react-router-dom";
import { icons } from "../utils/constants/icons";
import { route } from "../utils/constants/route";

type FooterProps = {
  active1?: string;
  active2?: string;
  active3?: string;
  active4?: string;
};

const Footer: FC<FooterProps> = (active) => {
  const cardsInput = [
    {
      class: active.active4,
      logo: icons.wallet_icon,
      route: route.WALLET,
    },
    {
      class: active.active2,
      logo: icons.requests_icon,
      route: route.PENDING_REQUEST,
    },
    {
      class: active.active3,
      logo: icons.swap_icon,
      route: route.TRANSACTIONS,
    },
    {
      class: active.active1,
      logo: icons.menu_icon,
      route: route.SETTINGS,
    },
  ];

  return (
    <div className="footer-menu">
      {cardsInput.map((card, index) => {
        return (
          <div key={index} data-id={index} className="footer-menu__item">
            <Link to={card.route}>
              <img src={card.logo} alt="" />
              <div className={classNames({ dot: card.class })}></div>
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Footer;
