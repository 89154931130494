import { FC, ReactElement } from "react";
import { Button } from "../components/Button";
import { TransactionTitle } from "../components/TransactionTitle";
import { statusType } from "../utils/constants/status";
import { StatusType } from "../utils/types/statusType";

type TransactionStatusProps = {
  title: string;
  text?: string;
  subText?: ReactElement | string;
  button?: {
    text: string;
    onClick: () => void;
  };
  status?: StatusType;
};

export const TransactionStatus: FC<TransactionStatusProps> = ({
  title,
  text,
  subText,
  button,
  status = statusType.FULFILLED,
}) => {
  return (
    <div className="transaction-success">
      <TransactionTitle status={status}>{title}</TransactionTitle>
      <div className="transaction-success-content">
        {text && <div className="transaction-success-text">{text}</div>}
        {subText && (
          <div className="transaction-success-subtext">{subText}</div>
        )}
        {button && (
          <Button
            className="transaction-success-button"
            onClick={button.onClick}
          >
            {button.text}
          </Button>
        )}
      </div>
    </div>
  );
};
