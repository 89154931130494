export default [
  {
    title: "What are the fees for selling gold? ",
    description:
      "A competitive transaction fee is charged for selling gold, disclosed at the time of the transaction without hidden costs.",
  },
  {
    title: "How long does it take to receive payment? ",
    description:
      "Payments are processed within 1-2 business days after sale confirmation, depending on the payment method.",
  },
  {
    title: "How do I fund my account? ",
    description:
      "Go to the deposit section, choose your preferred payment method, and follow the instructions to transfer funds to your account.",
  },
  {
    title: "Can I withdraw my funds at any time?",
    description:
      "Yes, withdrawals can be requested any time and are processed within 1-2 business days.",
  },
  {
    title: "Is there a minimum investment amount?",
    description:
      "A minimum investment amount applies, reflecting current market conditions. Check our website for up-to-date information.",
  },
  {
    title: "What if I need help or have more questions?",
    description: "Our customer support is available 24/7.",
  },
  {
    title: "How can I stay updated on gold prices?",
    description:
      "Our platform offers real-time tracking and alerts for gold prices.",
  },
  {
    title: "How does Digital Nugget protect my privacy?",
    description:
      "We are committed to protecting your privacy and personal information through strict adherence to data protection laws and our comprehensive privacy policy.",
  },
  {
    title: "Can I delete my account and data?",
    description:
      "Yes, you have the right to delete your account and request the removal of your personal data in accordance with privacy laws. Contact our support team for assistance.",
  },
];
