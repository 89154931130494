import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AxiosError } from "axios";
import { Location } from "history";
import { BuyTopUp } from "pages/BuyTopUp";
import { FC, useState } from "react";
import { Redirect, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { AuthRoute } from "./components/AuthRoute";
import { ProtectedRoute } from "./components/ProtectedRoute";
import CreditCardForm from "./pages/AddCard/CreditCardForm";
import Buy from "./pages/Buy";
import BuyPayment from "./pages/BuyPayment";
import Convert from "./pages/Convert";
import DepositPending from "./pages/DepositPending";
import EditContactModal from "./pages/EditContactModal";
import Feedback from "./pages/Feedback";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import IdVerification from "./pages/IdVerification";
import Login from "./pages/Login";
import { LostConnection } from "./pages/LostConnection";
import { Notifications as NotificationComponent } from "./pages/Notifications";
import Otp from "./pages/Otp";
import OtpUpdateEmail from "./pages/OtpUpdateEmail";
import OtpUpdatePhone from "./pages/OtpUpdatePhone";
import { Payment } from "./pages/Payment";
import PendingRequest from "./pages/PendingRequest";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Profile from "./pages/Profile";
import Sell from "./pages/Sell";
import SellPayment from "./pages/SellPayment";
import { SellSuccess } from "./pages/SellSuccess";
import Settings from "./pages/Settings";
import SignUp from "./pages/SignUp";
import TermAndConditions from "./pages/TermAndConditions";
import { TopUpSuccess } from "./pages/TopUpSucess";
import Transactions from "./pages/Transactions";
import Transfer from "./pages/Transfer";
import Verification from "./pages/Verification";
import Wallet from "./pages/Wallet";
import "./styles/style.css";
import { route } from "./utils/constants/route";
import { alertToast } from "./utils/helpers/alert";
import { Error } from "./utils/types/error";

const App: FC = () => {
  const [offline, setOffline] = useState(false);

  const handleError = (error: AxiosError<Error>): void => {
    if (error.response?.status) {
      alertToast({
        message: error.response?.data.error.message || "",
      });
    }

    if (error.code === "ERR_NETWORK") {
      setOffline(true);
    }
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: 1000 * 60 * 60 * 24,
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
    mutationCache: new MutationCache({
      onError: handleError,
    }),
    queryCache: new QueryCache({
      onError: handleError,
    }),
  });

  console.log(route);

  return (
    <QueryClientProvider client={queryClient}>
      <LostConnection offline={offline} setOffline={setOffline}>
        <Router>
          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            limit={3}
          />
          <Switch>
            <AuthRoute
              sensitive
              path="/:slug1*:slug2([A-Z]):slug3*"
              render={(props: { location: Location }) => (
                <Redirect to={props.location.pathname.toLowerCase()} />
              )}
            />
            <AuthRoute path={route.LOGIN} component={Login} />
            <AuthRoute path={route.VERIFY_LOGIN} component={Otp} />
            <AuthRoute path={route.SIGNUP} component={SignUp} />
            <AuthRoute path={route.VERIFY_SIGNUP} component={Otp} />
            <AuthRoute path={route.CONTACT} component={Contact} />
            <ProtectedRoute
              path={route.VERIFY_IDENTITY}
              component={IdVerification}
            />
            <ProtectedRoute
              path={route.VERIFICATION}
              component={Verification}
            />
            <ProtectedRoute
              path={route.PENDING_REQUEST}
              component={PendingRequest}
            />
            <ProtectedRoute
              path={route.TRANSACTIONS}
              component={Transactions}
            />
            <ProtectedRoute path={route.PROFILE} component={Profile} />
            <ProtectedRoute path={route.WALLET} component={Wallet} />
            <ProtectedRoute path={route.BUY} component={Buy} />
            <ProtectedRoute path={route.BUY_PAYMENT} component={BuyPayment} />
            <ProtectedRoute path={route.SELL} component={Sell} />
            <ProtectedRoute path={route.SELL_PAYMENT} component={SellPayment} />
            <ProtectedRoute path={route.FEEDBACK} component={Feedback} />
            <AuthRoute
              path={route.TERMS_AND_CONDITIONS}
              component={TermAndConditions}
            />
            <AuthRoute path={route.PRIVACY_POLICY} component={PrivacyPolicy} />
            <ProtectedRoute
              path={route.NOTIFICATIONS}
              component={NotificationComponent}
            />
            <ProtectedRoute
              path={route.DEPOSIT_PENDING}
              component={DepositPending}
            />
            <ProtectedRoute
              path={route.ADD_NUMBER}
              component={EditContactModal}
            />
            <ProtectedRoute
              path={route.ADD_EMAIL}
              component={EditContactModal}
            />
            {/* <ProtectedRoute path={route.SETTINGS} component={Settings} /> */}
            <ProtectedRoute
              path={route.UPDATE_NUMBER}
              component={OtpUpdatePhone}
            />
            <ProtectedRoute
              path={route.UPDATE_EMAIL}
              component={OtpUpdateEmail}
            />
            <ProtectedRoute path={route.ADD_CARD} component={CreditCardForm} />
            <ProtectedRoute path={route.TRANSFER} component={Transfer} />
            <ProtectedRoute path={route.CONVERT} component={Convert} />
            <ProtectedRoute path={route.BUY_TOP_UP} component={BuyTopUp} />
            <ProtectedRoute
              path={route.TOP_UP_SUCCESS}
              component={TopUpSuccess}
            />
            <ProtectedRoute path={route.SELL_SUCCESS} component={SellSuccess} />
            <ProtectedRoute path={route.PAYMENT} component={Payment} />
            <AuthRoute path={route.HOME} exact component={Home} />
            <Redirect from="*" to={route.HOME} />
          </Switch>
        </Router>
      </LostConnection>
    </QueryClientProvider>
  );
};

export default App;
