import { toast } from "react-toastify";
import "../../App.css";
import { icons } from "../constants/icons";

type Toast = {
  message: string;
};

export const alertToast = ({ message }: Toast): void => {
  toast.error(message, {
    type: "error",
    icon: <img src={icons.incorrect_icon} alt="error" />,
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

export const succesToast = ({ message }: Toast): void => {
  toast.success(message, {
    icon: <img src={icons.successIcon} alt="success" />,
    type: "success",
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};
