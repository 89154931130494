import { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { icons } from "../../utils/constants/icons";
import { route } from "../../utils/constants/route";

import "./style.css";

interface HeroProps {
  openBuySell: () => void;
}

const Hero: FC<HeroProps> = ({ openBuySell }) => {
  const history = useHistory();
  const handleClick = () => {
    history.push(route.LOGIN);
  };
  return (
    <div className="home-hero">
      <div className="home-container">
        <h1>
          Buy and sell gold with <span>Digital Nugget</span>
        </h1>

        <p>Seamlessly Purchase Gold With Your Debit or Credit Card</p>

        <Link className="hero_button sign-up_btn fill" to={route.LOGIN}>
          Sign up
          <img src={icons.arrow_right} />
        </Link>

        <button className="contact_us_btn hero_btn" onClick={handleClick}>
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default Hero;
