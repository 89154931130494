export const route = {
  HOME: "/",
  LOGIN: "/login",
  VERIFY_LOGIN: "/verify-login",
  SIGNUP: "/signup",
  VERIFY_SIGNUP: "/verify-signup",
  VERIFY_IDENTITY: "/verify-identity",
  VERIFICATION: "/verification",
  PENDING_REQUEST: "/pending-request",
  TRANSACTIONS: "/transactions",
  PROFILE: "/profile",
  WALLET: "/wallet",
  BUY: "/buy",
  BUY_PAYMENT: "/buy-payment",
  SELL: "/sell",
  SELL_PAYMENT: "/sell-payment",
  FEEDBACK: "/feedback",
  TERMS_AND_CONDITIONS: "/terms-and-conditions",
  PRIVACY_POLICY: "/privacy-policy",
  NOTIFICATIONS: "/notifications",
  DEPOSIT_PENDING: "/deposit-pending",
  ADD_NUMBER: "/add-number",
  ADD_EMAIL: "/add-email",
  SETTINGS: "/profile",
  UPDATE_NUMBER: "/update-number",
  UPDATE_EMAIL: "/update-email",
  ADD_CARD: "/add-card",
  TOP_UP_YOUR_WALLET: "/top-up-your-wallet",
  BANK_TRANSFER: "/bank-transfer",
  TRANSFER: "/transfer",
  CONVERT: "/convert",
  BUY_TOP_UP: "/top-up",
  TOP_UP_SUCCESS: "/top-up-success",
  SELL_SUCCESS: "/sell-success",
  LOST_CONNECTION: "/lost-connection",
  PAYMENT: "/payment",
  CONTACT: "/contact-us",
} as const;
