import { useInfiniteQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { FC, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import CloseNav from "../components/CloseNav";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { SkeletonList } from "../components/SkeletonList";
import { icons } from "../utils/constants/icons";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { screenSize } from "../utils/constants/screenSizes";
import { statusIcons } from "../utils/constants/status";
import { useSize } from "../utils/hooks/useSize";
import { TabTitle } from "../utils/pagetitle";

const Transactions: FC = () => {
  TabTitle("Digital Nugget | Transactions ");

  const history = useHistory();
  const isMobile = useSize(screenSize.MOBILE);

  const contentRef = useRef<HTMLDivElement>(null);

  const { isFetching, data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [queryNames.TRANSACTIONS],
    ({ pageParam = 1 }) => balanceApi.getTransactions(pageParam),
    {
      getNextPageParam: (lastPage) => {
        return (
          lastPage.currentPage < lastPage.totalPages && lastPage.currentPage + 1
        );
      },
    }
  );

  const scrollHandler = (): void => {
    if (
      document.documentElement.scrollHeight -
        (document.documentElement.scrollTop + window.innerHeight) <
        150 &&
      !isFetching &&
      hasNextPage
    ) {
      fetchNextPage();
    }
  };

  const pendingRequestButton = (): void => {
    history.push(route.PENDING_REQUEST);
  };

  useEffect(() => {
    if (
      data &&
      hasNextPage &&
      window.innerHeight + 52 >= (contentRef.current?.clientHeight || 0)
    ) {
      fetchNextPage();
      return;
    }
  }, [data]);

  useEffect(() => {
    document.addEventListener("scroll", scrollHandler);

    return () => {
      document.removeEventListener("scroll", scrollHandler);
    };
  }, [scrollHandler]);

  return (
    <div className="content" ref={contentRef}>
      <Sidebar activeRoute={route.TRANSACTIONS} />
      <div className="tab-content logged-content">
        <div className="tab-content__wrapper">
          {isMobile ? (
            <div className="tittle_position">
              <div className="settings_tittle">
                <div className="button_group">
                  <button className="but2" onClick={pendingRequestButton}>
                    Pending Requests
                  </button>
                  <button className="but1">Transactions</button>
                </div>
              </div>
            </div>
          ) : (
            <div className="tab-content__header">
              <div className="tab-content__header-text">
                <CloseNav title={"Transactions"} />
              </div>
            </div>
          )}
          <div className="walet__items walet__items--transactions">
            {isFetching || (data?.pages[0].data.length || 0) > 0 ? (
              data?.pages.map((page) => {
                return page.data.map((transaction) => {
                  return (
                    <div
                      className="walet__item waletitem__border"
                      key={transaction.id}
                    >
                      <div className="walet__item-left">
                        <div className="transactions__icon">
                          <img
                            src={statusIcons[transaction.status]}
                            alt="status"
                          />
                        </div>
                        <div className="walet__item-content">
                          <div className="walet__item-title">
                            {transaction.description}
                          </div>
                          <div className="walet__item-text">
                            <div className="walet__item-text">
                              {transaction.createdAt &&
                                format(
                                  new Date(transaction.createdAt),
                                  "dd MMM yyyy"
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="walet__item-value">
                        {transaction.type === "crypto_sell" && (
                          <>
                            -{transaction.cryptoAmount}{" "}
                            {transaction.cryptoCurrency}
                          </>
                        )}
                        {(transaction.type === "crypto_purchase" ||
                          transaction.type === "payment_request" ||
                          transaction.type === "transfer") && (
                          <>
                            {transaction.cryptoAmount}{" "}
                            {transaction.cryptoCurrency}
                          </>
                        )}
                      </div>
                    </div>
                  );
                });
              })
            ) : (
              <div className="empty-data">
                <img src={icons.transactions} alt="empty-data" />
                <p className="empty-data-text">There are no transactions</p>
              </div>
            )}
            {isFetching && <SkeletonList number={3} />}
          </div>
        </div>
      </div>
      <Footer active3="active" />
    </div>
  );
};

export default Transactions;
