import * as classNames from "classnames";
import { FC, useRef, useState } from "react";
import { icons } from "../utils/constants/icons";
import { screenSize } from "../utils/constants/screenSizes";
import { useClickOutside } from "../utils/hooks/useClickOutside";
import { useSize } from "../utils/hooks/useSize";

type DropdownItem = {
  title?: string;
  onClick?: () => void;
  id: number;
};

type DropdownProps = {
  title?: string;
  items?: DropdownItem[];
  selectedItemId?: number;
};

export const Dropdown: FC<DropdownProps> = ({
  title,
  items,
  selectedItemId,
}) => {
  const isMobile = useSize(screenSize.MOBILE);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const [showDropdow, setShowDropdown] = useState(false);
  const [selectedItem, setSelectedItem] = useState(
    items?.find((item) => item.id === selectedItemId)
  );

  const open = (): void => {
    setShowDropdown(true);
  };

  const close = (): void => {
    setShowDropdown(false);
  };

  const toggle = (): void => {
    if (showDropdow) {
      close();
    } else {
      open();
    }
  };

  const handleClick = (item: DropdownItem): void => {
    setSelectedItem(item);

    if (item.onClick) {
      item.onClick();
    }

    close();
  };

  useClickOutside<HTMLDivElement>(dropdownRef, close);

  return (
    <>
      {title && <h3 className="custom-input-title">{title}</h3>}
      <div className="converter__item">
        <div
          ref={dropdownRef}
          className="converter__input-wrapper space-between"
          onClick={toggle}
        >
          <span className="f14 f-600">{selectedItem?.title}</span>
          <img src={icons.arrowDown} alt="arrow" />
        </div>

        <div className={classNames("dropdown", { active: showDropdow })}>
          {isMobile && (
            <img
              src={icons.close}
              onClick={close}
              className="close"
              alt="close"
            />
          )}
          <div className="dropdown-list">
            {items?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="dropdown-item"
                  data-value="internal"
                  onClick={(): void => {
                    handleClick(item);
                  }}
                >
                  <span className="dropdown-item__name">{item?.title}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
