import { useMutation, useQuery } from "@tanstack/react-query";
import { ChangeEvent, FC, useState } from "react";
import { balanceApi } from "../api/balance/balanceApi";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import CloseNav from "../components/CloseNav";
import { CurrencyDropdown } from "../components/CurrencyDropdown";
import { Dropdown } from "../components/Dropdown";
import { MaxInputValue } from "../components/MaxInputValue";
import { NumberInput } from "../components/NumberInput";
import { PhoneData, PhoneInput } from "../components/PhoneInput";
import Sidebar from "../components/Sidebar";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { verivicationStatus } from "../utils/constants/verificationStatus";
import { alertToast } from "../utils/helpers/alert";
import { validatePhoneNumber } from "../utils/helpers/validations";
import { TransferConfirmation } from "./Popups/TransferConfirmation";

const Transfer: FC = () => {
  const { data: wallet } = useQuery([queryNames.WALLET], balanceApi.getState);
  const { data: user } = useQuery([queryNames.USER], userApi.getUserData);
  const { data: currencies } = useQuery(
    [queryNames.CURRENCIES],
    balanceApi.getCurrencies
  );

  const { mutate: checkUserExistMutation, isLoading } = useMutation(
    userApi.checkUserExist,
    {
      onSuccess: (data, payload) => {
        if (data.isExists) {
          setShowTransferConfirmation(true);
        } else {
          alertToast({
            message: `Recipient with number: ${payload} doesn't exist`,
          });
        }
      },
    }
  );

  const [showTransferConfirmation, setShowTransferConfirmation] =
    useState(false);
  const [transferType, setTransferType] = useState("internal");
  const [selectedCurrencies, setSelectedCurrencies] = useState({
    crypto: "BTC",
    cryptoValue: "",
  });
  const [walletAddress, setWalletAddress] = useState("");
  const [significantNumber, setSignificantNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const transferTo = transferType === "internal" ? phoneNumber : walletAddress;
  const isValid =
    (transferType === "internal" ? significantNumber : walletAddress) &&
    +selectedCurrencies.cryptoValue > 0;
  const cryptoBalance = wallet?.cryptos.find((crypto) => {
    return crypto.symbol === selectedCurrencies.crypto;
  });

  const handleChangeWalletAddress = (
    e: ChangeEvent<HTMLInputElement>
  ): void => {
    setWalletAddress(e.target.value);
  };

  const handleChangePhoneNumber = ({
    significantNumber,
    phoneNumber,
  }: PhoneData): void => {
    setSignificantNumber(significantNumber);
    setPhoneNumber(phoneNumber);
  };

  const handleContinue = (): void => {
    if (user?.kycStatus !== verivicationStatus.VERIFIED) {
      alertToast({
        message: "Transaction cannot be completed without KYC verification",
      });

      return;
    }

    if (!transferTo || !selectedCurrencies.cryptoValue) {
      return;
    }

    if (transferType === "external" && walletAddress.length < 16) {
      alertToast({ message: "External wallet must contain 16 characters" });
      return;
    }

    if (
      cryptoBalance &&
      +cryptoBalance.cryptoAmount < +selectedCurrencies.cryptoValue
    ) {
      alertToast({
        message: "You can't transfer more than you actually have",
      });

      return;
    }

    if (transferType === "internal" && phoneNumber === user?.phone) {
      alertToast({ message: "You can't request crypto from yourself" });

      return;
    }

    if (transferType === "internal" && validatePhoneNumber(significantNumber)) {
      checkUserExistMutation(phoneNumber);
    }

    if (transferType === "external") {
      setShowTransferConfirmation(true);
    }
  };

  return (
    <div className="content">
      <Sidebar activeRoute={route.TRANSFER} />
      <div className="tab-content">
        <div className="tab-content__wrapper">
          <div className="body_position">
            <div className="converter_body">
              <CloseNav title={"Transfer"} route={route.WALLET} />
              <div className="converter__wrapper converter__wrapper--buy">
                <Dropdown
                  title="I want to transfer to"
                  selectedItemId={1}
                  items={[
                    {
                      id: 1,
                      title: "Another Nixxe Account",
                      onClick: () => {
                        setTransferType("internal");
                        setWalletAddress("");
                      },
                    },
                    {
                      id: 2,
                      title: "An external wallet or other provider",
                      onClick: () => {
                        setTransferType("external");
                        setSignificantNumber("");
                      },
                    },
                  ]}
                />
                {transferType === "internal" ? (
                  <div className="converter__item">
                    <h3 className="custom-input-title">
                      Enter mobile number of recipient
                    </h3>
                    <div className="converter__input-wrapper space-between">
                      <PhoneInput
                        value={significantNumber}
                        onChange={handleChangePhoneNumber}
                        onSubmit={handleContinue}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="converter__item">
                    <h3 className="custom-input-title">Enter Wallet Address</h3>
                    <input
                      value={walletAddress}
                      onChange={handleChangeWalletAddress}
                      type="text"
                      placeholder="Wallet Address"
                      className="custom-text-input"
                    />
                  </div>
                )}
                <div className="converter__item">
                  <h3 className="custom-input-title">
                    Amount you want to send
                  </h3>
                  <div className="converter__input-wrapper space-between">
                    <NumberInput
                      value={selectedCurrencies.cryptoValue.toString()}
                      onChange={(value: string): void => {
                        setSelectedCurrencies({
                          ...selectedCurrencies,
                          cryptoValue: value,
                        });
                      }}
                      onSubmit={handleContinue}
                      placeholder="0.00"
                    />
                    <CurrencyDropdown
                      currencies={currencies?.cryptoCurrencies}
                      onClick={(row) => {
                        setSelectedCurrencies({
                          ...selectedCurrencies,
                          crypto: row.symbol,
                        });
                      }}
                      selectedCurrency={currencies?.cryptoCurrencies?.find(
                        ({ symbol }) => symbol === selectedCurrencies.crypto
                      )}
                    />
                  </div>
                  <div className="content-under-input">
                    <MaxInputValue
                      value={cryptoBalance?.cryptoAmount}
                      currency={selectedCurrencies.crypto}
                      onClick={(value) => {
                        setSelectedCurrencies({
                          ...selectedCurrencies,
                          cryptoValue: value.toString(),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              disabled={!isValid}
              loading={isLoading}
              onClick={handleContinue}
              id="continue-button"
              className="center"
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
      <TransferConfirmation
        showTransferConfirmation={showTransferConfirmation}
        setShowTransferConfirmation={setShowTransferConfirmation}
        transferData={{
          currency: selectedCurrencies.crypto,
          amount: selectedCurrencies.cryptoValue,
          phoneNumber,
          walletAddress,
          currentBalance: cryptoBalance?.cryptoAmount || 0,
        }}
        onSuccess={() => {
          setWalletAddress("");
          setPhoneNumber("");
          setSignificantNumber("");
          setTransferType("internal");
          setSelectedCurrencies({
            crypto: "BTC",
            cryptoValue: "",
          });
        }}
      />
    </div>
  );
};

export default Transfer;
