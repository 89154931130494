import Axios from "axios";
import { endpoints } from "../utils/constants/endpoints";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { route } from "../utils/constants/route";

const baseUrl = process.env.REACT_APP_API_URL;

export const axiosInstance = Axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(
      localStorageVariable.ACCESS_TOKEN
    )}`,
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use((config) => {
  if (
    config?.headers &&
    localStorage.getItem(localStorageVariable.ACCESS_TOKEN)
  ) {
    config.headers.Authorization = `Bearer ${localStorage.getItem(
      localStorageVariable.ACCESS_TOKEN
    )}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response.data,
  async (error) => {
    if (error.response?.status === 401) {
      try {
        const {
          data: { refreshToken, accessToken },
        } = await Axios.get(`${baseUrl}${endpoints.REFRESH_TOKEN}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              localStorageVariable.REFRESH_TOKEN
            )}`,
          },
        });

        localStorage.setItem(localStorageVariable.ACCESS_TOKEN, accessToken);
        localStorage.setItem(localStorageVariable.REFRESH_TOKEN, refreshToken);

        error.config.headers = {
          ...error.config.header,
          Authorization: `Bearer ${accessToken}`,
        };

        return axiosInstance.request(error.config);
      } catch (error) {
        if (error.response.status === 401) {
          localStorage.removeItem(localStorageVariable.ACCESS_TOKEN);
          localStorage.removeItem(localStorageVariable.REFRESH_TOKEN);

          window.location.href = route.LOGIN;
        } else {
          throw error;
        }
      }
    } else {
      throw error;
    }
  }
);
