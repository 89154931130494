import { FC } from "react";

type ProgressBarProps = {
  progress: number;
  step: number;
};

const ProgressBar: FC<ProgressBarProps> = ({ progress, step }) => {
  const ParentDiv = {
    height: "0.5rem",
    backgroundColor: "#FFC38C",
    width: "7.5rem",
    margin: "0 auto 50px auto",
    borderRadius: 40,
    position: "relative" as const,
  };

  const ChildDiv = {
    height: "100%",
    width: `${progress}%`,
    backgroundColor: "#FF7A00",
    borderRadius: 40,
    textAlign: "right" as const,
  };

  const progressText = {
    padding: 10,
    color: "black",
    fontWeight: 900,
  };
  const stepCount = {
    display: "flex",
    fontFamily: "PlusJakartaSans",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#FF7A00",
    position: "absolute" as const,
    left: "30%",
    letterSpacing: "1.5px",
  };

  return (
    <div style={ParentDiv}>
      <div style={ChildDiv}>
        <span style={progressText}></span>
        <span style={stepCount}>STEP {step}/2</span>
      </div>
    </div>
  );
};
export default ProgressBar;
