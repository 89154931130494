import { useMutation } from "@tanstack/react-query";
import { FC, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { authApi } from "../api/auth/authApi";
import { Country } from "../api/country/countrySchema";
import { Button } from "../components/Button";
import FooterOfWeb from "../components/FooterOfWeb";
import { PhoneData, PhoneInput } from "../components/PhoneInput";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { route } from "../utils/constants/route";
import { validatePhoneNumber } from "../utils/helpers/validations";
import { TabTitle } from "../utils/pagetitle";
import { icons } from "utils/constants/icons";

const Login: FC = () => {
  TabTitle("Digital Nugget | Login");
  const history = useHistory();

  const [phoneNumber, setPhoneNumber] = useState("");
  const [significantNumber, setSignificantNumber] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);

  const { isLoading, mutate: signInMutation } = useMutation(authApi.signIn, {
    onSuccess(data) {
      localStorage.setItem(localStorageVariable.ACCESS_TOKEN, data?.loginToken);
      localStorage.setItem(localStorageVariable.PHONE_NUMBER, phoneNumber);
      localStorage.setItem(
        localStorageVariable.COUNTRY,
        JSON.stringify(selectedCountry)
      );
      history.push(route.VERIFY_LOGIN);
    },
  });

  const signUp = (): void => {
    if (validatePhoneNumber(significantNumber)) {
      signInMutation({
        phoneNumber,
      });
    }
  };

  const handleChangePhoneNumber = ({
    significantNumber,
    country,
    phoneNumber,
  }: PhoneData): void => {
    setSignificantNumber(significantNumber);
    setSelectedCountry(country);
    setPhoneNumber(phoneNumber);
  };

  return (
    <div className="containerr">
      <header className="header">
        <div className="header__container">
          <div className="logo">
            <Link to={route.HOME}>
              {/* <button className="nixxeTittle">NIXXE</button> */}
              <img src={icons.digital_nugget_logo} alt="logo" />
            </Link>
          </div>
        </div>
      </header>
      <div className="sub-header">
        <div className="container">
          <h2 className="h2">Welcome to Digital Nugget</h2>
          <div className="sub-title">Let's start with your mobile number</div>
        </div>
      </div>
      <div className="auth-wrapper">
        <div className="converter">
          <div className="converter__wrapper phone__wrapper">
            <div className="converter__item">
              <PhoneInput
                value={significantNumber}
                onSubmit={signUp}
                onChange={handleChangePhoneNumber}
              />
            </div>
            <Button
              loading={isLoading}
              disabled={!significantNumber}
              onClick={signUp}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
      <FooterOfWeb />
    </div>
  );
};

export default Login;
