import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { balanceApi } from "../../api/balance/balanceApi";
import { animationDuration, OffCanvas } from "../../components/OffCanvas";
import { queryNames } from "../../utils/constants/queryNames";
import { ConfirmPopup } from "./ConfirmPopup";
import { SuccessPopup } from "./SuccessPopup";

type SwapData = {
  fromCurrency: string;
  forCurrency: string;
  fromAmount: string;
};

type ConvertConfirmationProps = {
  setShowConfirmation: (isShow: boolean) => void;
  showConfirmation: boolean;
  swapData: SwapData;
  exchangeRate: number;
  onSuccess: () => void;
};

export const ConvertConfirmation: FC<ConvertConfirmationProps> = ({
  setShowConfirmation,
  swapData,
  exchangeRate,
  showConfirmation,
  onSuccess,
}) => {
  const quryClient = useQueryClient();

  const [isComplete, setIsComplete] = useState(false);

  const { isLoading, mutate: convertCryptoMutation } = useMutation(
    balanceApi.convertCrypto,
    {
      onSuccess: () => {
        setIsComplete(true);
        quryClient.invalidateQueries([queryNames.WALLET]);
      },
    }
  );

  const close = (): void => {
    setShowConfirmation(false);

    setTimeout(() => {
      setIsComplete(false);
    }, animationDuration);
  };

  const onSuccessClose = () => {
    close();
    onSuccess();
  };

  const handleAccept = (): void => {
    const payload = {
      from: {
        currency: swapData.fromCurrency,
        type: "crypto",
        amount: +swapData.fromAmount,
      },
      to: {
        currency: swapData.forCurrency,
        type: "crypto",
      },
    };

    convertCryptoMutation(payload);
  };

  return (
    <OffCanvas
      show={showConfirmation}
      setShow={setShowConfirmation}
      onClose={isComplete ? onSuccessClose : close}
      ignoreClickOutsideList={["#continue-button"]}
      onSubmit={isComplete ? onSuccessClose : handleAccept}
    >
      {!isComplete ? (
        <ConfirmPopup
          title="Trade Confirmation"
          close={close}
          isLoading={isLoading}
          onAccept={handleAccept}
          onDecline={close}
          actionType="accept"
        >
          <div className="trade-container">
            <div className="trade-request-row">
              <span>Swap {swapData.fromCurrency}</span>
              <span className="trade-request-value">{swapData.fromAmount}</span>
            </div>
            <div className="trade-request-row">
              <span>For {swapData.forCurrency}</span>
              <span className="trade-request-value">{exchangeRate}</span>
            </div>
            <div className="trade-request-row">
              <span>Fees</span>
              <span className="f-300">2.00 USDT</span>
            </div>
          </div>
        </ConfirmPopup>
      ) : (
        <SuccessPopup close={onSuccessClose} title="Trade Complete" />
      )}
    </OffCanvas>
  );
};
