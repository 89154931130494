import { FC } from "react";

type PaymentMethodsProps = {
  src?: string;
};

const CurrencyImage: FC<PaymentMethodsProps> = ({ src }) => {
  return <img src={src} alt={"currency"} height={"24"} width={"24"} />;
};

export default CurrencyImage;
