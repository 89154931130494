import { FC } from "react";
import CloseNav from "../components/CloseNav";
// import Sidebar from "../components/Sidebar";
// import { route } from "../utils/constants/route";
import { TabTitle } from "../utils/pagetitle";
import ReactHtmlParser from "react-html-parser";
import { _policyPrivacy } from "utils/constants/policyPrivacy";

const PrivacyPolicy: FC = () => {
  TabTitle("Digital Nugget | Privacy Policy");

  return (
    <div className="content stickyContent">
      {/* <Sidebar activeRoute={route.SETTINGS} />   */}
      <div
        className={"tab-content hideScrollbar overflowContent"}
        id="scrollable"
      >
        <div className="termBody">
          <div className="privacy-header">
            <CloseNav title={"Privacy Policy"} route={"settings"} />
          </div>
          <div className="policy-content">
            {ReactHtmlParser(_policyPrivacy)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
