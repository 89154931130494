import { useEffect, useState } from "react";
import { Focused } from "react-credit-cards";
import { validateInfo } from "../../pages/AddCard/validateInfo";
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from "../helpers/general";

export type ValidateFormValues = {
  cardName: string;
  cardNumber: string;
  cardExpiration: string;
  cardSecurityCode: string;
  focus: Focused;
};

const useForm = () => {
  const [values, setValues] = useState<ValidateFormValues>({
    cardName: "",
    cardNumber: "",
    cardExpiration: "",
    cardSecurityCode: "",
    focus: "name",
  });
  const [errors, setErrors] = useState({
    cardName: false,
    cardNumber: false,
    cardExpiration: false,
    cardSecurityCode: false,
  });

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { name } = e.target;

    setValues({
      ...values,
      focus: name === "cardSecurityCode" ? "cvc" : (name as Focused),
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "cardNumber") {
      value = formatCreditCardNumber(value);
    } else if (name === "cardExpiration") {
      value = formatExpirationDate(value);
    } else if (name === "cardSecurityCode") {
      value = formatCVC(value);
    }

    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setErrors(validateInfo(values));
  };

  useEffect(() => {
    setErrors(validateInfo(values));
  }, [values]);

  return { handleChange, handleFocus, handleSubmit, values, errors };
};

export default useForm;
