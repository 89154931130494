import { FC } from "react";
import { icons } from "../../utils/constants/icons";

import "./style.css";

const Info: FC = () => {
  return (
    <div className="home-infos home-section">
      <div className="home-container">
        <h2 className="home-title white">Fast - Easy - Secure </h2>

        <p className="home-p white">
          Quickly buy and sell gold with Digital Nugget. Your gateway to trading
          gold assets quickly, safely, and hassle free.
        </p>

        <div className="info-items">
          <div className="info-item">
            <div className="icon">
              <img src={icons.fair_price} alt="fair price" />
            </div>

            <div>
              <h3>Instant Liquidity</h3>
              <p>
                Access immediate buying and selling options with our platform,
                providing you with unparalleled flexibility and control over
                your gold investments.
              </p>
            </div>
          </div>

          <div className="info-item">
            <div className="icon">
              <img src={icons.high_limits} alt="Secure Transactions" />
            </div>
            <div>
              <h3>Secure Transactions</h3>
              <p>
                Enjoy peace of mind with our state-of-the-art security measures,
                ensuring your gold trading is protected at every step.
              </p>
            </div>
          </div>

          <div className="info-item">
            <div className="icon">
              <img src={icons.real_pricing} alt="Real-Time Pricing" />
            </div>
            <div>
              <h3>Real-Time Pricing</h3>
              <p>
                Stay ahead with real-time gold prices and market data, ensuring
                you make transactions based on the most current information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Info;
