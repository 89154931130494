export default [
  {
    title: "Sign up",
    description:
      "Create your account and complete a simple KYC verification to ensure a secure trading experience",
  },
  {
    title: "Add Funds",
    description:
      "Deposit funds securely through a variety of payment options to start your trading journey.",
  },
  {
    title: "Buy Gold",
    description:
      "Access our platform to purchase gold effortlessly, with real-time market prices and insights to guide your decisions.",
  },
  {
    title: "Sell Gold",
    description:
      "Effortlessly liquidate your gold holdings through our platform, taking advantage of fast transaction speeds and favorable market prices.",
  },
  {
    title: "Easy Withdrawals",
    description:
      "Withdraw your profits or capital swiftly and securely, with our streamlined bank transfer process.",
  },
  {
    title: "Portfolio Management",
    description:
      "Easily monitor and adjust your investments to align with your trading strategy and goals.",
  },
];
