import { FC } from "react";
import { Link } from "react-router-dom";
import { route } from "../../utils/constants/route";

import "./style.css";

const Contact: FC = () => {
  return (
    <div className="home-contact home-section" id="contact">
      <div className="home-container">
        <h2 className="home-title center">Need to speak with someone?</h2>

        <p className="home-p center">
          Our support team is ready to help you at every step of the way
        </p>

        <Link to={route.CONTACT} className="button fill">
          Contact us
        </Link>
      </div>
    </div>
  );
};

export default Contact;
