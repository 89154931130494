import * as classNames from "classnames";
import { FC, useRef, useState } from "react";
import { CurrenciesResponse, Currency } from "../api/balance/balanceSchema";
import { icons } from "../utils/constants/icons";
import { screenSize } from "../utils/constants/screenSizes";
import { useClickOutside } from "../utils/hooks/useClickOutside";
import { useSize } from "../utils/hooks/useSize";
import CurrencyImage from "./CurrencyImage";
import Spiner from "./Spinner";

type CurrencyDropdownType = {
  currencies?: CurrenciesResponse;
  onClick: (row: Currency) => void;
  selectedCurrency?: Currency;
  id?: number;
  disabled?: boolean;
  oneItemOnly?: boolean;
};

export const CurrencyDropdown: FC<CurrencyDropdownType> = ({
  currencies,
  selectedCurrency,
  onClick,
  id,
  disabled = false,
  oneItemOnly = false,
}) => {
  const isMobile = useSize(screenSize.MOBILE);
  const currencyRef = useRef(null);
  const dropdowndId = id ? `dropdown-${id}` : "dropdown";

  const [showDropdow, setShowDropdown] = useState(false);

  const open = (): void => {
    setShowDropdown(true);
  };

  const close = (): void => {
    setShowDropdown(false);
  };

  const toggle = (): void => {
    if (disabled) return;
    if (showDropdow) {
      close();
    } else {
      open();
    }
  };

  const handleSelect = (currency: Currency): void => {
    onClick(currency);
    close();
  };

  useClickOutside<HTMLDivElement>(currencyRef, close, [`#${dropdowndId}`]);

  return (
    <>
      <div
        id={dropdowndId}
        className="selected-currency-container"
        onClick={toggle}
      >
        {selectedCurrency ? (
          <>
            <CurrencyImage src={selectedCurrency?.icon} />
            <span className="selected-currency">
              {selectedCurrency?.symbol}
            </span>
            {oneItemOnly ? (
              <span style={{ width: "14px" }}></span>
            ) : (
              <img src={icons.arrowDown} alt="arrow" />
            )}
          </>
        ) : (
          <Spiner />
        )}
      </div>

      <div
        ref={currencyRef}
        className={classNames("dropdown currencies", {
          active: showDropdow && currencies,
        })}
      >
        {isMobile && (
          <img
            src={icons.close}
            onClick={close}
            className="close"
            alt="close"
          />
        )}
        <div className="dropdown-list">
          {currencies?.map((row) => {
            return (
              <div
                className="dropdown-item"
                key={row.symbol}
                onClick={(): void => {
                  handleSelect(row);
                }}
              >
                <CurrencyImage src={row.icon} />
                <span className="dropdown-item__name">{row.symbol}</span>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};
