import { useMutation, useQuery } from "@tanstack/react-query";
import { ChangeEvent, FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import CloseNav from "../components/CloseNav";
import Sidebar from "../components/Sidebar";
import Spiner from "../components/Spinner";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { succesToast } from "../utils/helpers/alert";
import { TabTitle } from "../utils/pagetitle";

const Feedback: FC = () => {
  TabTitle("Digital Nugget | Feedback ");

  const history = useHistory();

  const { isLoading: userIsLoading, data: user } = useQuery(
    [queryNames.USER],
    userApi.getUserData,
    {
      onSuccess: (data) => {
        setFeedbackData({
          name: `${data?.firstName} ${data?.lastName}`,
          email: data?.email || "",
          message: "",
        });
      },
    }
  );

  const [feedbackData, setFeedbackData] = useState({
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email || "",
    message: "",
  });

  const { isLoading, mutate: sendFeedbackMutation } = useMutation(
    userApi.sendFeedback,
    {
      onSuccess: () => {
        history.push(route.SETTINGS);
        succesToast({
          message: "You have successfully send a feedback message!",
        });
      },
    }
  );

  const sendFeedback = (): void => {
    sendFeedbackMutation(feedbackData);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFeedbackData({
      ...feedbackData,
      [e.target.name]: e.target.value,
    });
  };

  const isValid = Object.values(feedbackData).every((value) => value);

  return (
    <div className="content">
      <Sidebar activeRoute={route.SETTINGS} />
      <div className="tab-content">
        {userIsLoading ? (
          <Spiner />
        ) : (
          <div className="body_position">
            <div className="converter_body">
              <CloseNav title={"Feedback"} route={route.SETTINGS} />
              <div className="feedback">
                <p className="faadback_paragraph">
                  Your feedback is important to us.
                </p>
                <div className="faadback__item">
                  <label htmlFor="Name" className="faadback_label">
                    Name
                  </label>
                  <div className="faadback__input-wrapper">
                    <input
                      type="text"
                      name="name"
                      className="faadback_input"
                      placeholder="Enter your name"
                      style={{ textTransform: "capitalize" }}
                      value={feedbackData.name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="faadback__item">
                  <label htmlFor="Email" className="faadback_label">
                    Email
                  </label>
                  <div className="faadback__input-wrapper">
                    <input
                      type="text"
                      name="email"
                      placeholder="Enter your email"
                      className="faadback_input"
                      value={feedbackData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="faadback__item">
                  <label htmlFor="Email" className="faadback_label">
                    Feedback
                  </label>
                  <div className="faadback__input-wrapper taxtarea_input">
                    <textarea
                      name="message"
                      placeholder="Enter your message"
                      className="faadback_input"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <Button
              loading={isLoading}
              disabled={!isValid}
              onClick={sendFeedback}
              className="center"
            >
              Send
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Feedback;
