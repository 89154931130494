import { useMutation, useQuery } from "@tanstack/react-query";
import CloseNav from "components/CloseNav";
import CurrencyImage from "components/CurrencyImage";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import Sidebar from "../components/Sidebar";
import Spiner from "../components/Spinner";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { verivicationStatus } from "../utils/constants/verificationStatus";
import { limitsPlaceholder } from "../utils/helpers/limitsPlaceholder";
import { TabTitle } from "../utils/pagetitle";
import { SelectedCurrencies } from "../utils/types/exchange";

const BuyPayment: FC = () => {
  TabTitle("Digital Nugget | Buy Payment");

  const history = useHistory();
  const selectedCurrencies: SelectedCurrencies = JSON.parse(
    localStorage.getItem(localStorageVariable.EXCHANGE) || "{}"
  );

  const payload = {
    from: {
      currency: selectedCurrencies.currency,
      type: "fiat",
      amount: +selectedCurrencies.currencyValue,
    },
    to: {
      currency: selectedCurrencies.crypto,
      type: "crypto",
    },
  };

  const { isLoading: userIsLoading, data: user } = useQuery(
    [queryNames.USER],
    userApi.getUserData
  );

  const { data: globalSettings } = useQuery(
    [queryNames.GLOBAL_SETTINGS],
    userApi.getGlobalSettings
  );

  const { data: currencies } = useQuery(
    [queryNames.CURRENCIES],
    balanceApi.getCurrencies
  );

  const { data: exchangeData } = useQuery(
    [queryNames.EXCHANGE_VALUE, selectedCurrencies],
    () => {
      return balanceApi.getExchangeRate(payload);
    }
  );

  const { mutate: topUpMutation, isLoading: topUpIsLoading } = useMutation(
    balanceApi.topUp,
    {
      onSuccess: (data) => {
        history.push({ pathname: route.BUY_TOP_UP, state: data.url });
      },
    }
  );

  const handleBuyCrypto = (): void => {
    topUpMutation({
      amount: +selectedCurrencies?.currencyValue || 2000,
      cryptoCurrency: selectedCurrencies?.crypto,
      fiatCurrency: selectedCurrencies?.currency,
      paymentMethod: "Wallet",
    });
  };

  useEffect(() => {
    if (user && user?.kycStatus !== verivicationStatus.VERIFIED) {
      history.push(route.WALLET);
    }
  }, [user]);

  return (
    <div>
      <div className="content">
        <Sidebar activeRoute={route.WALLET} />
        <div className="tab-content logged-content">
          {userIsLoading ? (
            <Spiner />
          ) : (
            <div className="tab-content__wrapper">
              <div className="body_position">
                <div className="converter_body">
                  <CloseNav title={"Buy"} route={route.BUY} />
                  <div className="converter__wrapper converter__wrapper--buy">
                    <div className="converter__item">
                      <h3 className="h3">You will spend</h3>
                      <div className="converter__input-wrapper">
                        <input
                          defaultValue={Number(
                            selectedCurrencies?.currencyValue
                          ).toFixed(2)}
                          type="text"
                          placeholder={limitsPlaceholder(
                            globalSettings?.minDeposit,
                            globalSettings?.maxDeposit
                          )}
                          className="converter__input input_disabled no-border"
                          readOnly
                        />
                        <div className="selected-currency-container justify-end">
                          <CurrencyImage
                            src={
                              currencies?.fiatCurrencies.find(
                                ({ symbol }) =>
                                  symbol === selectedCurrencies.currency
                              )?.icon
                            }
                          />
                          <span className="selected-currency">
                            {selectedCurrencies?.currency}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="converter__item">
                      <h3 className="h3">You will recieve</h3>
                      <div className="converter__input-wrapper">
                        <input
                          defaultValue={
                            selectedCurrencies.crypto === "USDT"
                              ? exchangeData?.exchangeRate.toFixed(2)
                              : exchangeData?.exchangeRate
                          }
                          type="text"
                          placeholder={limitsPlaceholder(
                            globalSettings?.minDeposit,
                            globalSettings?.maxDeposit
                          )}
                          className="converter__input input_disabled no-border"
                          readOnly
                        />
                        <div className="selected-currency-container justify-end">
                          <CurrencyImage
                            src={
                              currencies?.cryptoCurrencies.find(
                                ({ symbol }) =>
                                  symbol === selectedCurrencies.crypto
                              )?.icon
                            }
                          />
                          <span className="selected-currency">
                            {selectedCurrencies?.crypto}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  loading={topUpIsLoading}
                  onClick={handleBuyCrypto}
                  className="center"
                >
                  Buy
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuyPayment;
