import { FC, PropsWithChildren } from "react";
import { Button } from "../../components/Button";
import { PopupTitle } from "./PopupTitle";

type ConfirmPopupProps = {
  title: string;
  isLoading: boolean;
  close: () => void;
  onAccept?: () => void;
  onDecline?: () => void;
  actionType: "decline" | "accept";
  alertInfo?: string;
};

export const ConfirmPopup: FC<PropsWithChildren<ConfirmPopupProps>> = ({
  title,
  close,
  children,
  isLoading,
  onAccept,
  onDecline,
  actionType,
  alertInfo,
}) => {
  return (
    <div className="offcanvas-content">
      <div className="offcanvas-top">
        <PopupTitle close={close}>{title}</PopupTitle>
        <div className="offcanvas-body">{children}</div>
      </div>
      <div className="offcanvas-bottom">
        {alertInfo && <div className="transaction-alert-info">{alertInfo}</div>}
        <div className="modal-buttons">
          <Button
            variaint="transparent"
            loading={isLoading && actionType === "decline"}
            onClick={onDecline}
          >
            Decline
          </Button>
          <Button
            onClick={onAccept}
            loading={isLoading && actionType === "accept"}
          >
            Accept
          </Button>
        </div>
      </div>
    </div>
  );
};
