import { Button } from "../components/Button";
import { succesToast } from "../utils/helpers/alert";
import { TabTitle } from "../utils/pagetitle";
import { useMutation } from "@tanstack/react-query";
import { ChangeEvent, FC, useState } from "react";
import { useHistory } from "react-router-dom";
import CloseNav from "../components/CloseNav";
import { userApi } from "../api/user/userApi";

import { route } from "../utils/constants/route";

const Contact: FC = () => {
  TabTitle("Digital Nugget | Contact Us");
  const history = useHistory();

  const [feedbackData, setFeedbackData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const { isLoading, mutate: sendFeedbackMutation } = useMutation(
    userApi.sendFeedback,
    {
      onSuccess: () => {
        history.push(route.HOME);
        succesToast({
          message: "You have successfully send a feedback message!",
        });
      },
    }
  );
  const sendFeedback = (): void => {
    sendFeedbackMutation(feedbackData);
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    setFeedbackData({
      ...feedbackData,
      [e.target.name]: e.target.value,
    });
  };

  const isValid = Object.values(feedbackData).every((value) => value);

  return (
    <div className="content">
      <div className="tab-content">
        <div className="body_position">
          <div className="converter_body">
            <CloseNav title={"Contact Us"} route={route.HOME} />
            <div className="feedback">
              <p className="faadback_paragraph">
                We value your feedback, questions, and inquiries. Feel free to
                get in touch with us using the information provided below. Our
                dedicated team is here to assist you.
                <br />
                <br />
                <span className="strong">Email: </span> hello@digitalnugget.io
                <br />
                <span className="strong">Business Hours: </span>
                Mon - Fri, 9:00 AM to 6:00 PM GMT
              </p>
              <br />
              <p className="faadback_paragraph">
                You can also reach out to us using the contact form below.
                Please provide your name, email address, and a brief message,
                and we'll get back to you as soon as possible.
              </p>
              <br />
              <div className="faadback__item">
                <label htmlFor="Name" className="faadback_label">
                  Name
                </label>
                <div className="faadback__input-wrapper">
                  <input
                    type="text"
                    name="name"
                    className="faadback_input"
                    style={{ textTransform: "capitalize" }}
                    value={feedbackData.name}
                    onChange={handleChange}
                    placeholder="Enter your name"
                  />
                </div>
              </div>
              <div className="faadback__item">
                <label htmlFor="Email" className="faadback_label">
                  Email
                </label>
                <div className="faadback__input-wrapper">
                  <input
                    type="text"
                    name="email"
                    className="faadback_input"
                    value={feedbackData.email}
                    onChange={handleChange}
                    placeholder="Enter your email"
                  />
                </div>
              </div>
              <div className="faadback__item">
                <label htmlFor="Email" className="faadback_label">
                  Contact Us
                </label>
                <div className="faadback__input-wrapper taxtarea_input">
                  <textarea
                    name="message"
                    placeholder="Enter your message"
                    className="faadback_input"
                    onChange={handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <Button
            loading={isLoading}
            disabled={!isValid}
            onClick={sendFeedback}
            className="center"
          >
            Send
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Contact;
