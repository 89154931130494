export const localStorageVariable = {
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  USER_IS_ACTIVE: "user_is_active",
  OTP: "otp",
  EMAIL: "email",
  PHONE_NUMBER: "phone_number",
  EXCHANGE: "exchange",
  COUNTRY: "country",
} as const;
