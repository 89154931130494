import { FC, useEffect, useState } from "react";
import Loader from "../components/Loader";
import Sidebar from "../components/Sidebar";
import { icons } from "../utils/constants/icons";
import { route } from "../utils/constants/route";
import { TabTitle } from "../utils/pagetitle";

const DepositPending: FC = () => {
  TabTitle("Digital Nugget | Deposit Pending");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  return (
    <div className="content">
      <Sidebar activeRoute={route.WALLET} />
      <div className="tab-content">
        <div className="tab-content__wrapper">
          <div className="body_position">
            <div className="withdraw">
              {loading ? (
                <div className="containerOfDeposit2">
                  <Loader />
                  <div className="HeaderTitleDeposit">Deposit Pending</div>
                  <label className="labelStyleInput2">
                    Don't navigate away from this screen. Payment is being
                    processed.
                  </label>
                </div>
              ) : (
                <div className="containerOfDeposit3">
                  <img src={icons.elipseICON} alt="" />
                  <div className="HeaderTitleDeposit2">Deposit Successful</div>
                  <label className="labelStyleInput3">£ 3,000.00</label>
                  <br />
                  <label className="labelStyleInput2">
                    has been credited to your account
                  </label>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DepositPending;
