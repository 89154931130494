import { RefObject, useEffect } from "react";

export const useClickOutside = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback?: () => void,
  ignoreList?: string[]
): void => {
  const handleClick = (e: Event): void => {
    const isClikedOutsideIgnoreElements = ignoreList
      ? ignoreList
          ?.flatMap((element) => {
            return Array.from(document.querySelectorAll(element));
          })
          .every((element) => {
            return !element?.contains(e.target as Node);
          })
      : true;

    const isClickedOutsideRef =
      ref?.current && !ref?.current?.contains(e.target as Node);

    if (isClickedOutsideRef && isClikedOutsideIgnoreElements) {
      callback?.();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};
