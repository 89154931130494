import { AxiosInstance } from "axios";
import { endpoints } from "../../utils/constants/endpoints";
import { axiosInstance } from "../axiosInstance";
import {
  AuthPayload,
  AuthResponse,
  GetSumSubAccessTokenResponse,
  VerifyPayload,
  VerifyResponse,
} from "./authSchema";

class AuthApi {
  #requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.#requestInstance = axiosInstance;
  }

  signIn = async (userData: AuthPayload): Promise<AuthResponse> => {
    return this.#requestInstance.post(endpoints.OTP_SINGIN, userData);
  };

  signOut = async (): Promise<void> => {
    return this.#requestInstance.post(endpoints.SIGN_OUT);
  };

  verifyOtp = async (verifyData: VerifyPayload): Promise<VerifyResponse> => {
    return this.#requestInstance.post(endpoints.VERIFY_OTP, verifyData);
  };

  verifyPhone = async (verifyData: VerifyPayload): Promise<VerifyResponse> => {
    return this.#requestInstance.post(endpoints.VERIFY_NUMBER, verifyData);
  };

  verifyEmail = async (verifyData: VerifyPayload): Promise<VerifyResponse> => {
    return this.#requestInstance.post(endpoints.VERIFY_EMAIL, verifyData);
  };

  getSumSubAccessToken = async (): Promise<GetSumSubAccessTokenResponse> => {
    return this.#requestInstance.get(endpoints.GET_SUMSUB_ACCESS_TOKEN);
  };
}

export const authApi = new AuthApi(axiosInstance);
