import { useLayoutEffect, useState } from "react";

export const useSize = (size: number): boolean => {
  const [isSize, setIsSize] = useState(false);

  const resizeHandler = (): void => {
    const isChangeSize = window.document.documentElement.clientWidth <= size;

    setIsSize(isChangeSize);
  };

  useLayoutEffect(() => {
    setIsSize(window.document.documentElement.clientWidth <= size);

    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  return isSize;
};
