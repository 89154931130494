import { FC } from "react";
import { Link } from "react-router-dom";
import CloseNav from "./CloseNav";
import ReactHtmlParser from "react-html-parser";
import { _termsAndConditions } from "utils/constants/termsAndCondictions";
import { _policyPrivacy } from "utils/constants/policyPrivacy";

const FooterOfWeb: FC = () => {
  // Current Year
  const date = new Date();
  const currentYear = date.getFullYear();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__text">
          <Link data-bs-toggle="modal" data-bs-target="#terms" to="">
            Terms and Conditions{" "}
          </Link>
          <div
            className="modal fade"
            id="terms"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-fullscreen">
              <div className="modal-content">
                <div className="modal-body">
                  <div
                    className="nonLogin-content overflowContent"
                    id="scrollable"
                  >
                    <div className="termBody">
                      <div className="privacy-header">
                        <CloseNav title={"Terms and Conditions"} close />
                      </div>
                      <div className="policy-content">
                        {ReactHtmlParser(_termsAndConditions)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          |<span className="space-right"></span>
          <Link data-bs-toggle="modal" data-bs-target="#policy" to="">
            Privacy Policy
          </Link>
          <div
            className="modal fade"
            id="policy"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content">
                <div className="modal-body">
                  <div
                    className="nonLogin-content overflowContent"
                    id="scrollPolcy"
                  >
                    <div className="termBody">
                      <div className="privacy-header">
                        <CloseNav title={"Privacy Policy"} close />
                      </div>
                      <div className="policy-content">
                        {ReactHtmlParser(_policyPrivacy)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <span className="space-right"></span>|{" "} */}
          {/* <Link to="contact-us">Contact Us</Link> */}
        </div>
        <div className="footer__copy container">
          {currentYear}
          <span className="space-right"></span> © Digital Nugget - All rights
          reserved, 2024
        </div>
      </div>
    </footer>
  );
};

export default FooterOfWeb;
