import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";
import { balanceApi } from "../api/balance/balanceApi";
import { CurrenciesResponse, Currency } from "../api/balance/balanceSchema";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import CloseNav from "../components/CloseNav";
import { CurrencyDropdown } from "../components/CurrencyDropdown";
import { MaxInputValue } from "../components/MaxInputValue";
import { NumberInput } from "../components/NumberInput";
import Sidebar from "../components/Sidebar";
import Spiner from "../components/Spinner";
import { limits } from "../utils/constants/limits";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { verivicationStatus } from "../utils/constants/verificationStatus";
import { alertToast } from "../utils/helpers/alert";
import { useDebounce } from "../utils/hooks/useDebounce";
import { ConvertConfirmation } from "./Popups/ConvertConfirmation";

export const Convert: FC = () => {
  const [swapData, setSwapData] = useState({
    fromCurrency: "USDT",
    forCurrency: "BTC",
    fromAmount: "",
    fromData: [] as CurrenciesResponse,
    forData: [] as CurrenciesResponse,
  });

  const { data: wallet } = useQuery([queryNames.WALLET], balanceApi.getState);
  const { data: user } = useQuery([queryNames.USER], userApi.getUserData);

  const { data: currencies } = useQuery(
    [queryNames.CURRENCIES],
    balanceApi.getCurrencies,
    {
      onSuccess: (data) => {
        setSwapData({
          fromCurrency: "USDT",
          forCurrency: "BTC",
          fromAmount: "",
          fromData: data.cryptoCurrencies,
          forData: data.cryptoCurrencies,
        });
      },
    }
  );

  const [showConfirmation, setShowConfirmation] = useState(false);

  const debounce = useDebounce(swapData, 100);

  const { data: exchangeData, isFetching: isLoadingExchangeData } = useQuery(
    [queryNames.EXCHANGE_VALUE, debounce],
    () => {
      const payload = {
        from: {
          currency: swapData.fromCurrency,
          type: "crypto",
          amount: +swapData.fromAmount,
        },
        to: {
          currency: swapData.forCurrency,
          type: "crypto",
        },
      };

      return balanceApi.getExchangeRate(payload);
    },
    {
      enabled: !!debounce.fromAmount,
    }
  );

  const handleSelectFrom = (selectedCurency: Currency): void => {
    setSwapData({
      ...swapData,
      fromCurrency: selectedCurency.symbol,
      forCurrency:
        selectedCurency.symbol === swapData.forCurrency
          ? swapData.fromCurrency
          : swapData.forCurrency,
    });
  };

  const handleSelectTo = (selectedCurency: Currency): void => {
    setSwapData({
      ...swapData,
      fromCurrency:
        selectedCurency.symbol === swapData.fromCurrency
          ? swapData.forCurrency
          : swapData.fromCurrency,
      forCurrency: selectedCurency.symbol,
    });
  };

  const handleChangeSwapInput = (value: string): void => {
    setSwapData({
      ...swapData,
      fromAmount: value,
    });
  };

  const cryptoValue = wallet?.cryptos.find((crypto) => {
    return crypto.symbol === swapData.fromCurrency;
  });

  const handleConvert = (): void => {
    if (user?.kycStatus !== verivicationStatus.VERIFIED) {
      alertToast({
        message: "Transaction cannot be completed without KYC verification",
      });

      return;
    }

    if (cryptoValue && cryptoValue.cryptoAmount >= +swapData.fromAmount) {
      setShowConfirmation(true);
    } else {
      alertToast({ message: "You can't convert more than you actually have" });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fromData, forData, ...tradeData } = swapData;

  return (
    <div className="content">
      <Sidebar activeRoute={route.CONVERT} />
      <div className="tab-content">
        <div className="tab-content__wrapper">
          <div className="body_position">
            <div className="converter_body">
              <CloseNav title={"Convert | Swap"} route={route.WALLET} />
              <div className="converter__wrapper converter__wrapper--buy">
                <div className="converter__item">
                  <h3 className="custom-input-title">I want to swap</h3>
                  <div className="converter__input-wrapper">
                    <NumberInput
                      value={swapData.fromAmount}
                      onChange={handleChangeSwapInput}
                      placeholder="0.00"
                      onSubmit={handleConvert}
                      max={limits.EXCHANGE}
                    />
                    <CurrencyDropdown
                      currencies={swapData.fromData}
                      onClick={handleSelectFrom}
                      selectedCurrency={currencies?.cryptoCurrencies?.find(
                        ({ symbol }) => symbol === swapData.fromCurrency
                      )}
                      id={1}
                    />
                  </div>
                  <div className="content-under-input">
                    <MaxInputValue
                      value={cryptoValue?.cryptoAmount}
                      currency={swapData.fromCurrency}
                      onClick={(value) => {
                        setSwapData({
                          ...swapData,
                          fromAmount: value.toString(),
                        });
                      }}
                    />
                  </div>
                </div>
                <div className="converter__item">
                  <h3 className="custom-input-title">For</h3>
                  <div className="converter__input-wrapper">
                    <div className="converter__input">
                      {isLoadingExchangeData ? (
                        <Spiner sell />
                      ) : (
                        <>
                          {exchangeData?.exchangeRate ? (
                            exchangeData?.exchangeRate
                          ) : (
                            <span className="fontResetHome">0.00</span>
                          )}
                        </>
                      )}
                    </div>
                    <CurrencyDropdown
                      currencies={swapData.forData}
                      onClick={handleSelectTo}
                      selectedCurrency={currencies?.cryptoCurrencies?.find(
                        ({ symbol }) => symbol === swapData.forCurrency
                      )}
                      id={2}
                    />
                  </div>
                </div>
              </div>
            </div>
            <Button
              id="continue-button"
              className="center"
              disabled={
                +swapData.fromAmount === 0 || !exchangeData?.exchangeRate
              }
              onClick={handleConvert}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
      <ConvertConfirmation
        showConfirmation={showConfirmation && !!exchangeData}
        setShowConfirmation={setShowConfirmation}
        swapData={tradeData}
        exchangeRate={exchangeData?.exchangeRate || 0}
        onSuccess={(): void => {
          setSwapData({
            fromCurrency: "USDT",
            forCurrency: "BTC",
            fromAmount: "",
            fromData: currencies?.cryptoCurrencies || [],
            forData: currencies?.cryptoCurrencies || [],
          });
        }}
      />
    </div>
  );
};

export default Convert;
