import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

type ResendCodeProps<P, R> = {
  payload: P;
  resendCode: (input: P) => Promise<R>;
  onSuccess?: (data: R) => void;
};

const cooldownInSeconds = 120;

export const ResendCode = <I, R>({
  payload,
  resendCode,
  onSuccess,
}: ResendCodeProps<I, R>) => {
  const [countdown, setCountdown] = useState(cooldownInSeconds);

  const { mutate: resendVerifcationCodeMutation } = useMutation(resendCode, {
    onSuccess: (data) => {
      onSuccess?.(data);
      setCountdown(cooldownInSeconds);
    },
  });

  const handleResendCode = (): void => {
    resendVerifcationCodeMutation(payload);
  };

  useEffect(() => {
    if (countdown > 0) {
      const timer = setInterval(() => setCountdown(countdown - 1), 1000);
      return () => clearInterval(timer);
    }
  }, [countdown]);

  const minutes = Math.floor(countdown / 60);
  const seconds = countdown % 60;
  const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;

  return countdown ? (
    <div className="resend-code-timer">
      You can resend the code in {formattedTime}
    </div>
  ) : (
    <button className="resend-code" onClick={handleResendCode}>
      Resend verification code
    </button>
  );
};
