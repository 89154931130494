import * as valid from "card-validator";
import { ValidateFormValues } from "../../utils/hooks/useForm";

export const validateInfo = (values: ValidateFormValues) => {
  return {
    cardNumber: valid.number(values.cardNumber).isValid,
    cardExpiration: valid.expirationDate(values.cardExpiration).isValid,
    cardSecurityCode: valid.cvv(values.cardSecurityCode).isValid,
    cardName: valid.cardholderName(values.cardName).isValid,
  };
};
