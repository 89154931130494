import { useLocation } from "react-router-dom";

export const useSearchParams = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const params: { [key: string]: string } = {};

  for (const [key, value] of searchParams) {
    params[key] = value;
  }

  return params;
};
