import SumsubWebSdk from "@sumsub/websdk-react";
import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { authApi } from "../api/auth/authApi";
import { Button } from "../components/Button";
import CloseNav from "../components/CloseNav";
import Spiner from "../components/Spinner";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { succesToast } from "../utils/helpers/alert";

const reviewStatuses = ["pending", "completed", "onHold"];

type SumSubPayload = { reviewStatus: "pending" | "completed" | "onHold" };

const Verification: FC = () => {
  const {
    isFetching,
    data: kycAccessToken,
    refetch,
  } = useQuery([queryNames.SUMSUB_ACCESS_TOKEN], authApi.getSumSubAccessToken);

  const [reviewStatus, setReviewStatus] = useState("");

  const handleOnMessage = (_, payload: SumSubPayload): void => {
    if (payload?.reviewStatus) {
      setReviewStatus(payload.reviewStatus);
    }
  };

  const handleFinish = (): void => {
    if (reviewStatus === "completed") {
      succesToast({
        message: "KYC completed successfully",
      });
    }
  };

  return (
    <div className="containerr">
      <div className="verification-container">
        <div className="mx-4" onClick={handleFinish}>
          {isFetching ? <Spiner /> : <CloseNav route={route.WALLET} />}
        </div>
        {kycAccessToken && !isFetching && (
          <SumsubWebSdk
            accessToken={kycAccessToken.sumsubAccessToken}
            config={{
              lang: "en",
              uiConf: {
                customCssStr: `
                :root {
                  --orange-color: #ff7a00;
                  --success-color: #ff7a00;
                }
                #loader .round-icon,
                .submit,
                .round-icon {
                  background: var(--orange-color);
                  background-image: unset !important;
                }
                .check-done .rotation-indicator {
                  stroke: var(--orange-color) !important;
                };
                `,
              },
            }}
            options={{ addViewportTag: false, adaptIframeHeight: true }}
            expirationHandler={refetch}
            onMessage={handleOnMessage}
          />
        )}
        {reviewStatuses.includes(reviewStatus) && (
          <Link to={route.WALLET}>
            <Button onClick={handleFinish}>Finish</Button>
          </Link>
        )}
      </div>
    </div>
  );
};

export default Verification;
