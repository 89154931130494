import * as classNames from "classnames";
import { ChangeEvent, FC, KeyboardEvent } from "react";

export type NumberInputProps = {
  value: string;
  onChange: (value: string) => void;
  onSubmit?: () => void;
  placeholder?: string;
  readOnly?: boolean;
  defaultValue?: number;
  disableCharacters?: string[];
  className?: string;
  max?: number;
  maxLength?: number;
  disabled?: boolean;
};

const prohibitedCharacters = ["e", "+", " "];

export const NumberInput: FC<NumberInputProps> = ({
  placeholder,
  onChange,
  onSubmit,
  readOnly,
  defaultValue,
  disableCharacters,
  className = "",
  value,
  max = Infinity,
  maxLength = Infinity,
  disabled = false,
}) => {
  const handleSubmit = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      onSubmit?.();
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const nativeIventData = (e.nativeEvent as InputEvent).data || "";
    const value = e.target.value;
    const [number, float] = value.split(".");

    if (
      prohibitedCharacters
        .concat(disableCharacters || [])
        .includes(nativeIventData) ||
      +value > max ||
      +value < 0 ||
      /^0[0-9]+/.test(number) ||
      (float && float?.length > 15) ||
      value.length > maxLength ||
      isNaN(+value)
    ) {
      return;
    }

    onChange?.(value);
  };

  return (
    <input
      type="text"
      placeholder={placeholder}
      className={classNames("converter__input", className)}
      value={value}
      readOnly={readOnly}
      defaultValue={defaultValue}
      onChange={handleChange}
      onKeyDown={handleSubmit}
      disabled={disabled}
    />
  );
};
