import { FC } from "react";
import { icons } from "../../utils/constants/icons";
import { Link } from "react-router-dom";
import { route } from "../../utils/constants/route";

import "./style.css";

const Footer: FC = () => {
  const handleRedirectToHome = () => {
    window.location.href = route.HOME;
  };
  return (
    <div className="home-footer home-section">
      <div className="home-container">
        <div className="home-footer_upper">
          <div className="home-footer_info">
            <img
              src={icons.digital_nugget_footer_logo}
              alt="logo"
              onClick={handleRedirectToHome}
              className="footer_logo"
            />
            <p className="home-footer_info-p">
              2024 Digital Nugget. All rights reserved. Digital Nugget is an
              online trading platform dedicated to gold trading, providing users
              with a seamless experience to buy and sell gold securely and
              efficiently.
            </p>
          </div>

          <div className="right-side">
            <img src={icons.pci} />
            <p className="home-footer_info-p">Secured cardholder data</p>
          </div>
        </div>

        <div className="home-footer_under">
          <p>© Digital Nugget - All rights reserved, 2024</p>{" "}
          <div>
            <Link to={route.TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
            <Link to={route.PRIVACY_POLICY}>Privacy Policy</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
