import { AxiosInstance } from "axios";
import { CurrencyVariants } from "../../utils/constants/currencyType";
import { endpoints } from "../../utils/constants/endpoints";
import { axiosInstance } from "../axiosInstance";
import {
  AddCardPayload,
  ConvertCryptoResponse,
  Currencies,
  CurrenciesResponse,
  ExchangePayload,
  ExchangeRateResponse,
  ExchangeResponse,
  PaymentStatusPayload,
  PaymentStatusResponse,
  StateResponse,
  TopUpPayload,
  TopUpResponse,
  TransactionsResponse,
  TransferPayload,
  TransferResponse,
} from "./balanceSchema";
import { icons } from "utils/constants/icons";

const normalizeCurrencies = (
  data: CurrenciesResponse,
  type: CurrencyVariants
) => {
  return data
    .filter((currency) => currency.type === type)
    .sort((currentCurrency, nextCurrency) =>
      currentCurrency.symbol.localeCompare(nextCurrency.symbol)
    );
};

class BalanceApi {
  #requestInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.#requestInstance = axiosInstance;
  }

  getState = async (): Promise<StateResponse> => {
    return this.#requestInstance.get(endpoints.GET_STATE);
  };

  getExchangeRate = async (
    exchangeRate?: ExchangePayload
    //add another default payload
  ): Promise<ExchangeRateResponse> => {
    // const defaultPayload = {
    //   from: {
    //     currency: "USD",
    //   },
    //   to: {
    //     currency: "AU",
    //   },
    // };

    const currencies = exchangeRate?.to?.currency === "AU" ? "XAU" : "";
    const apiKey = "d74a46814fad9df57f7c3455023c3d39";
    const requestForExchange = `?api_key=${apiKey}&base=${exchangeRate?.from?.currency}&currencies=${currencies}`;

    return this.#requestInstance.get(
      endpoints.EXCHANGE_RATE + `${requestForExchange}`
    );
  };

  getTransactions = async (page: number): Promise<TransactionsResponse> => {
    return this.#requestInstance.get(`${endpoints.TRANSACTIONS}?page=${page}`);
  };

  buyCrypto = async (
    buyCryptoData: ExchangePayload
  ): Promise<ExchangeResponse> => {
    return this.#requestInstance.post(endpoints.BUY_CRYPTO, buyCryptoData);
  };

  sellCrypto = async (
    sellCryptoData: ExchangePayload
  ): Promise<ExchangeResponse> => {
    return this.#requestInstance.post(endpoints.SELL_CRYPTO, sellCryptoData);
  };

  addCard = async (card: AddCardPayload): Promise<void> => {
    return this.#requestInstance.post(endpoints.ADD_CARD, card);
  };

  topUp = async (topUpData: TopUpPayload): Promise<TopUpResponse> => {
    return this.#requestInstance.post(endpoints.BALANCE_TOP_UP, topUpData);
  };

  convertCrypto = async (
    convert: ExchangePayload
  ): Promise<ConvertCryptoResponse> => {
    return this.#requestInstance.post(endpoints.CONVERT_CRYPTO, convert);
  };

  transfer = async (transfer: TransferPayload): Promise<TransferResponse> => {
    return this.#requestInstance.post(endpoints.TRANSFER, transfer);
  };

  getCurrencies = async (): Promise<Currencies> => {
    const { data } = await this.#requestInstance.get<CurrenciesResponse>(
      endpoints.GET_ALL_CURRENCIES
    );

    return {
      cryptoCurrencies: [
        {
          id: 0,
          symbol: "AU",
          type: "crypto",
          name: "gold",
          icon: icons.au_logo,
        },
      ],
      fiatCurrencies: normalizeCurrencies(data, "fiat"),
    };
  };

  getPaymentStatus = async ({
    paymentId,
  }: PaymentStatusPayload): Promise<PaymentStatusResponse> => {
    return this.#requestInstance.get(
      `${endpoints.PAYMENT_STATUS}/${paymentId}`
    );
  };
}

export const balanceApi = new BalanceApi(axiosInstance);
