import { alertToast } from "./alert";

export const validatePhoneNumber = (phoneNumber: string): boolean => {
  if (phoneNumber.length === 0) {
    alertToast({ message: "Enter a phone number" });

    return false;
  }

  if (phoneNumber.length <= 4) {
    alertToast({ message: "Phone number must be at least 5 digits" });

    return false;
  }

  if (phoneNumber.length > 13) {
    alertToast({ message: "Phone number must be a maximum of 13 digits" });

    return false;
  }

  return true;
};

export const validateEmail = (email: string): boolean => {
  if (email.length === 0) {
    alertToast({ message: "Enter your email" });

    return false;
  }

  const regexp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!regexp.test(email.toLowerCase())) {
    alertToast({ message: "Email is invalid" });

    return false;
  }

  return true;
};
