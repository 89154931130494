import { useMutation } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import CloseNav from "../components/CloseNav";
import { PhoneData, PhoneInput } from "../components/PhoneInput";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { route } from "../utils/constants/route";
import {
  validateEmail,
  validatePhoneNumber,
} from "../utils/helpers/validations";
import { TabTitle } from "../utils/pagetitle";

const EditContactModal: FC = () => {
  TabTitle("Digital Nugget | Profile");

  const history = useHistory();
  const location = useLocation();

  const isEmailPage = location.pathname.includes(route.ADD_EMAIL);
  const isPhoneNumberPage = location.pathname.includes(route.ADD_NUMBER);

  const { mutate: updatePhoneMutation, isLoading: updatePhoneIsLoading } =
    useMutation(userApi.updatePhone, {
      onSuccess: (data) => {
        localStorage.setItem(localStorageVariable.OTP, data.verifyPhoneOtp);
        localStorage.setItem(localStorageVariable.PHONE_NUMBER, phoneNumber);
        history.push(route.UPDATE_NUMBER);
      },
    });

  const { mutate: updateEmailMutation, isLoading: updateEmailIsLoading } =
    useMutation(userApi.updateEmail, {
      onSuccess: (data) => {
        localStorage.setItem(
          localStorageVariable.OTP,
          data.verifyEmailOtp.toString()
        );
        localStorage.setItem(localStorageVariable.EMAIL, email);
        history.push(route.UPDATE_EMAIL);
      },
    });

  const isLoading = updatePhoneIsLoading || updateEmailIsLoading;

  const [significantNumber, setSignificantNumber] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const editUserData = (): void => {
    if (isPhoneNumberPage && validatePhoneNumber(significantNumber)) {
      updatePhoneMutation({
        phoneNumber,
      });

      return;
    }

    if (isEmailPage && validateEmail(email)) {
      updateEmailMutation({
        email,
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === "Enter") {
      editUserData();
    }
  };

  const handleChangePhoneNumber = ({
    significantNumber,
    phoneNumber,
  }: PhoneData): void => {
    setSignificantNumber(significantNumber);
    setPhoneNumber(phoneNumber);
  };

  return (
    <div className="content">
      <div className="edit-contact-wrapper">
        <div className="body_position">
          <div>
            <CloseNav title="Edit contact info" route={route.PROFILE} />
            <div className="edit-contact">
              <div className="converter__item">
                {isPhoneNumberPage && (
                  <>
                    <label className="phone1">Phone Number</label>
                    <PhoneInput
                      value={significantNumber}
                      onChange={handleChangePhoneNumber}
                      onSubmit={editUserData}
                    />
                  </>
                )}

                {isEmailPage && (
                  <div className="edit1">
                    <label className="phone1">Email Address</label>
                    <input
                      id="email"
                      type="email"
                      className="text-input"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      placeholder="Enter your email address"
                      onKeyDown={handleKeyDown}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {isPhoneNumberPage && (
            <Button
              disabled={!significantNumber}
              onClick={editUserData}
              loading={isLoading}
              className="center"
            >
              Save
            </Button>
          )}
          {isEmailPage && (
            <Button
              disabled={!email}
              loading={isLoading}
              onClick={editUserData}
              className="center"
            >
              Save
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
export default EditContactModal;
