import { useQuery } from "@tanstack/react-query";
import { FC, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import { userApi } from "../api/user/userApi";
import { Button } from "../components/Button";
import { CurrencyDropdown } from "../components/CurrencyDropdown";
// import FooterOfWeb from "../components/FooterOfWeb";
import { NumberInput } from "../components/NumberInput";
import Spiner from "../components/Spinner";
import "../styles/style.css";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { limitsPlaceholder } from "../utils/helpers/limitsPlaceholder";
import { useDebounce } from "../utils/hooks/useDebounce";
import { TabTitle } from "../utils/pagetitle";
import { icons } from "../utils/constants/icons";

import { screenSize } from "../utils/constants/screenSizes";
import { useSize } from "../utils/hooks/useSize";

import Hero from "../components/home/Hero";
import Info from "../components/home/Info";
import HowToBuy from "../components/home/HowToBuy";
import Faq from "../components/home/Faq";
import Contact from "../components/home/Contact";
import Footer from "../components/home/Footer";

const Home: FC = () => {
  TabTitle("Digital Nugget | Home ");
  const history = useHistory();

  const isTablet = useSize(screenSize.TABLET);
  const [selectedCurrencies, setSelectedCurrencies] = useState({
    currency: "USD",
    currencyValue: "",
    crypto: "AU",
    cryptoValue: "",
  });

  const [menuOpen, setMenuOpen] = useState(false);
  const [buySellOpen, setBuySellOpen] = useState(false);

  const debounce = useDebounce(selectedCurrencies, 100);

  const { data: currencies } = useQuery(
    [queryNames.CURRENCIES],
    balanceApi.getCurrencies
  );

  const { data: globalSettings } = useQuery(
    [queryNames.GLOBAL_SETTINGS],
    userApi.getGlobalSettings
  );

  const { data: exchangeData, isFetching } = useQuery(
    [queryNames.EXCHANGE_VALUE, debounce],
    () => {
      const payload = {
        from: {
          currency: selectedCurrencies.currency,
          type: "fiat",
          amount: +selectedCurrencies.currencyValue,
        },
        to: {
          currency: selectedCurrencies.crypto,
          type: "crypto",
        },
      };

      return balanceApi.getExchangeRate(payload);
    },
    {
      enabled: !!debounce.currencyValue,
    }
  );

  const handleBuy = (): void => {
    if (
      (globalSettings &&
        +selectedCurrencies.currencyValue < globalSettings?.minDeposit) ||
      !exchangeData?.rates
    ) {
      return;
    }

    localStorage.setItem(
      localStorageVariable.EXCHANGE,
      JSON.stringify(selectedCurrencies)
    );
    history.push(route.LOGIN);
  };

  useEffect(() => {
    if ((menuOpen || buySellOpen) && isTablet)
      document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [menuOpen, isTablet, buySellOpen]);

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const openBuySell = () => {
    setBuySellOpen(true);
    closeMenu();
  };

  const closeBuySell = () => {
    setBuySellOpen(false);
  };
  const valueOfAu = selectedCurrencies?.crypto === "AU" ? "XAU" : "";
  const value = exchangeData?.rates?.[valueOfAu];

  const valueOfAuFromMoney = +selectedCurrencies?.currencyValue * value;

  return (
    <div className="containerr">
      <div className="home-page">
        <div className="home-left">
          <header className="header">
            <div className="header__container home-container login-header">
              <div className="logo">
                <Link to={route.HOME}>
                  <img src={icons.digital_nugget_logo} alt="logo" />
                </Link>
              </div>
              <div
                className={`header_links_container ${menuOpen ? "active" : ""}`}
              >
                <span />
                <ul className="header_links">
                  <li>
                    <a href="#how-to-buy" onClick={closeMenu}>
                      How It Works
                    </a>
                  </li>
                  <li>
                    <a href="#faq" onClick={closeMenu}>
                      FAQ
                    </a>
                  </li>
                  <li>
                    <a href="/contact-us" onClick={closeMenu}>
                      Contact Us
                    </a>
                  </li>
                </ul>
                <div className="header-buttons">
                  <button className="buy-sell" onClick={openBuySell}>
                    Buy
                  </button>
                  <Link to={route.LOGIN}>
                    <button className="login-link fill">Log in</button>
                  </Link>
                </div>
              </div>

              <div
                className={`burger-icon ${menuOpen ? "active" : ""}`}
                onClick={() => setMenuOpen(!menuOpen)}
              >
                <span className="first"></span>
                <span className="second"></span>
                <span className="third"></span>
              </div>
            </div>
          </header>

          <Hero openBuySell={openBuySell} />
          <Info />
          <HowToBuy />
          <Faq />

          <Contact />
          <Footer />
        </div>

        <div className={`home-right ${buySellOpen ? "open" : ""}`}>
          <div>
            <div className="back-arrow" onClick={closeBuySell}>
              <img src={icons.backArrow} />
            </div>
            <div className="sub-header">
              <h2 className="h2">Quickly buy and sell gold</h2>
              <div>
                <div className="sub-title">
                  Buy Gold instantly 24/7 using your debit or credit card
                </div>
              </div>
            </div>
            <div className="converter">
              <div className="converter__wrapper">
                <div className="converter__item">
                  <h3 className="h3">I want to spend</h3>
                  <div className="converter__input-wrapper">
                    <NumberInput
                      value={selectedCurrencies.currencyValue}
                      onChange={(value: string): void => {
                        setSelectedCurrencies({
                          ...selectedCurrencies,
                          currencyValue: value,
                        });
                      }}
                      max={globalSettings?.maxDeposit}
                      placeholder={limitsPlaceholder(
                        globalSettings?.minDeposit,
                        globalSettings?.maxDeposit
                      )}
                      onSubmit={handleBuy}
                    />
                    <CurrencyDropdown
                      currencies={currencies?.fiatCurrencies}
                      onClick={(row) => {
                        setSelectedCurrencies({
                          ...selectedCurrencies,
                          currency: row.symbol,
                        });
                      }}
                      selectedCurrency={currencies?.fiatCurrencies?.find(
                        ({ symbol }) => symbol === selectedCurrencies.currency
                      )}
                      id={1}
                    />
                  </div>
                </div>
                <div className="converter__item">
                  <h3 className="h3">You will recieve</h3>
                  <div className="converter__input-wrapper">
                    <div className="converter__input">
                      {isFetching && <Spiner sell={true} />}
                      {!isFetching && valueOfAuFromMoney > 0 && (
                        <>
                          {valueOfAuFromMoney > 0
                            ? valueOfAuFromMoney.toFixed(4)
                            : ""}
                        </>
                      )}
                      {!isFetching && !valueOfAuFromMoney && (
                        <span className="fontResetHome">0.00</span>
                      )}
                    </div>
                    <CurrencyDropdown
                      currencies={currencies?.cryptoCurrencies}
                      onClick={(row) => {
                        setSelectedCurrencies({
                          ...selectedCurrencies,
                          crypto: row.symbol,
                        });
                      }}
                      selectedCurrency={currencies?.cryptoCurrencies?.find(
                        ({ symbol }) => symbol === selectedCurrencies.crypto
                      )}
                      id={2}
                      oneItemOnly={true}
                      disabled={true}
                    />
                  </div>
                </div>
                <Button
                  onClick={handleBuy}
                  disabled={
                    globalSettings &&
                    +selectedCurrencies.currencyValue <
                      globalSettings?.minDeposit
                  }
                >
                  Buy
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
