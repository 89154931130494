import { icons } from "./icons";

export const statusIcons = {
  PENDING: icons.pending,
  FULFILLED: icons.checkmark,
  REJECTED: icons.cancel,
} as const;

export const statusType = {
  PENDING: "PENDING",
  FULFILLED: "FULFILLED",
  REJECTED: "REJECTED",
} as const;
