import { useQuery } from "@tanstack/react-query";
import * as classNames from "classnames";
import { FC, useLayoutEffect, useRef, useState } from "react";
import { countryApi } from "../api/country/countryApi";
import { Country } from "../api/country/countrySchema";
import { userApi } from "../api/user/userApi";
import { allflags, icons } from "../utils/constants/icons";
import { queryNames } from "../utils/constants/queryNames";
import CloseNav from "./CloseNav";
import { NumberInput } from "./NumberInput";
import Spiner from "./Spinner";

export type PhoneData = {
  phoneNumber: string;
  country: Country | null;
  significantNumber: string;
};

type PhoneInputProps = {
  value: string;
  onSubmit?: (phoneData: PhoneData) => void;
  onChange?: (phoneData: PhoneData) => void;
};

export const PhoneInput: FC<PhoneInputProps> = ({
  value,
  onSubmit,
  onChange,
}) => {
  const inputRef = useRef<HTMLDivElement>(null);

  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [search, setSearch] = useState("");

  const { data: countries } = useQuery(
    [queryNames.COUNTRIES],
    countryApi.getCountries
  );

  const { data: countryCode } = useQuery(
    [queryNames.COUNTRY_CODE],
    userApi.getCountryCode
  );

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(e.target.value);
  };

  const handleSelectCountry = (country: Country): void => {
    setSelectedCountry(country);
    setSearch("");
    onChange?.({
      phoneNumber: `+${country.phonePrefix}${value}`,
      country,
      significantNumber: value,
    });
  };

  const handleChangePhoneNumber = (value: string): void => {
    onChange?.({
      phoneNumber: `+${selectedCountry?.phonePrefix}${value}`,
      country: selectedCountry,
      significantNumber: value,
    });
  };

  const handleSubmit = (): void => {
    onSubmit?.({
      phoneNumber: `+${selectedCountry?.phonePrefix}${value}`,
      country: selectedCountry,
      significantNumber: value,
    });
  };

  useLayoutEffect(() => {
    if (countries && countryCode) {
      const foundCountry =
        countries?.find((country) => country.cca2 === countryCode?.cca2Code) ||
        countries[0];

      setSelectedCountry(foundCountry);
    }
  }, [countryCode, countries]);

  return (
    <div className="converter__input-wrapper" ref={inputRef}>
      {!countryCode && !countries && <Spiner />}
      {selectedCountry && (
        <>
          <div
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            className="phone"
          >
            <img
              src={selectedCountry?.flagUrl}
              className="phone-country"
              alt="flag"
            />
            <div className="phone-prefix" style={{ cursor: "pointer" }}>
              +{selectedCountry?.phonePrefix}
            </div>
            <img
              src={icons.downArrow}
              style={{ marginRight: "15px", cursor: "pointer" }}
              alt="arrow"
            />
          </div>
          <NumberInput
            value={value}
            onChange={handleChangePhoneNumber}
            onSubmit={handleSubmit}
            disableCharacters={["."]}
            className="phone-input"
          />
        </>
      )}
      <div
        className="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog  modal-fullscreen">
          <div className="modal-content">
            <div className="modal-body content-center-modal">
              <CloseNav title={"Select country international code"} close />
              <div className="searchImg">
                <img src={icons.search_icon} alt="" />
                <input
                  className="inputStyle"
                  placeholder="Search"
                  value={search}
                  onChange={handleChangeSearch}
                />
              </div>
              {countries
                ?.filter((country) =>
                  country.name.toLowerCase().includes(search.toLowerCase())
                )
                .map((country, index) => {
                  return (
                    <div
                      key={`${country.name}-${country.phonePrefix}`}
                      data-id={index}
                      className=" d-flex "
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <button
                        className={classNames("styleofinputsmodal", {
                          modalBorder:
                            selectedCountry?.phonePrefix ===
                            country.phonePrefix,
                        })}
                        onClick={(): void => {
                          handleSelectCountry(country);
                        }}
                      >
                        <div className="d-flex justify-content-between w-100">
                          <div className="d-flex align-items-center">
                            <img
                              src={allflags + country.cca2 + ".png"}
                              className="flag_img"
                              alt="flag"
                            />
                            <p className="decimalText">
                              +{country.phonePrefix}
                            </p>
                            <p className="decimalText">{country.name}</p>
                          </div>
                          <div className="d-flex align-items-center">
                            {selectedCountry?.cca2 === country.cca2 && (
                              <img
                                src={icons.checkIcon}
                                className="checkiconinmoda"
                                alt="country"
                              />
                            )}
                          </div>
                        </div>
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
