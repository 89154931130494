import { FC, useState, useEffect } from "react";
import { icons } from "../../utils/constants/icons";

import data from "./how-to-buy";

import "./style.css";

interface YourItemType {
  id: number;
  title: string;
  description: string;
  open: boolean;
}

const HowToBuy: FC = () => {
  const [open, setOpen] = useState<YourItemType[]>([]);

  useEffect(() => {
    const newOpenState = data.map((item: YourItemType) => ({
      ...item,
      open: false,
    }));
    setOpen(newOpenState);
  }, []);
  const toggleOpen = (i: number) => {
    const newOpenState: YourItemType[] = open.map((item, index) =>
      index === i ? { ...item, open: !item.open } : { ...item, open: false }
    );

    setOpen(newOpenState);
  };

  return (
    <div className="home-howtobuy home-section" id="how-to-buy">
      <div className="home-container">
        <h2 className="home-title">How It Works</h2>

        <div className="how-to-buy_container grid_container">
          {open.map((item, index) => (
            <div
              key={index}
              className={`how-to-buy_item grid_item ${
                item.open ? "active" : ""
              }`}
              onClick={() => toggleOpen(index)}
            >
              <div className="title_container">
                <h3>
                  {String(index + 1).padStart(2, "0")}
                  {" " + item.title}
                </h3>

                {item.open ? (
                  <img src={icons.minus} />
                ) : (
                  <img src={icons.plus} />
                )}
              </div>

              {item.open && <p>{item.description}</p>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
