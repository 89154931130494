import { FC, PropsWithChildren } from "react";

type PopupTitleProps = {
  close: () => void;
};

export const PopupTitle: FC<PropsWithChildren<PopupTitleProps>> = ({
  close,
  children,
}) => {
  return (
    <div className="offcanvas-header">
      <button
        type="button"
        className="btn-close"
        aria-label="Close"
        onClick={close}
      ></button>
      <p className="modal_title">{children}</p>
    </div>
  );
};
