import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { pendingRequestApi } from "../../api/pendingRequest/pendingRequestApi";
import { PendingRequestType } from "../../api/pendingRequest/pendingRequestSchema";
import { userApi } from "../../api/user/userApi";
import { OffCanvas, animationDuration } from "../../components/OffCanvas";
import { icons } from "../../utils/constants/icons";
import { queryNames } from "../../utils/constants/queryNames";
import { route } from "../../utils/constants/route";
import { verivicationStatus } from "../../utils/constants/verificationStatus";
import { alertToast, succesToast } from "../../utils/helpers/alert";
import { ConfirmPopup } from "./ConfirmPopup";

type PendingRequestConfirmationProps = {
  show: boolean;
  setShow: (show: boolean) => void;
  paymentRequest: PendingRequestType | null;
  setPaymentRequest: (pendingRequest: PendingRequestType | null) => void;
  requestBalance: {
    amount: number;
    currency: string;
    insufficientBalance: number;
  };
};

export const PendingRequestConfirmation: FC<
  PendingRequestConfirmationProps
> = ({ show, setShow, paymentRequest, setPaymentRequest, requestBalance }) => {
  const queryClient = useQueryClient();
  const history = useHistory();

  const [actionType, setActionType] = useState<"accept" | "decline">("accept");
  const { data: user } = useQuery([queryNames.USER], userApi.getUserData);
  const close = (): void => {
    setShow(false);

    setTimeout(() => {
      setPaymentRequest(null);
    }, animationDuration);
  };

  const { mutate: pendingRequestsMutation, isLoading } = useMutation(
    pendingRequestApi.pendingRequestAction,
    {
      onSuccess: (_, action) => {
        close();
        queryClient.invalidateQueries([queryNames.PENDING_REQUEST]);
        succesToast({
          message:
            action.type === "accept"
              ? `${Number(paymentRequest?.cryptoAmount).toString()} ${
                  paymentRequest?.cryptoCurrency
                } sent to ${paymentRequest?.sourceFrom}`
              : "Payment request has been cancelled",
        });
      },
      onError: () => {
        if (requestBalance.insufficientBalance) {
          history.push(route.BUY);
        }
      },
    }
  );

  const handlePaymentRequest = (type: string): void => {
    if (user?.kycStatus !== verivicationStatus.VERIFIED) {
      alertToast({
        message: "Transaction cannot be completed without KYC verification",
      });

      return;
    }

    if (paymentRequest?.id) {
      pendingRequestsMutation({ id: paymentRequest?.id, type });
    }
  };

  const handleAccept = (): void => {
    handlePaymentRequest("accept");
    setActionType("accept");
  };

  const handleDecline = (): void => {
    handlePaymentRequest("decline");
    setActionType("decline");
  };

  return (
    <OffCanvas
      show={show}
      setShow={setShow}
      onClose={close}
      ignoreClickOutsideList={["#pending-requests-items"]}
      onSubmit={handleAccept}
    >
      <ConfirmPopup
        close={close}
        isLoading={isLoading}
        onAccept={handleAccept}
        onDecline={handleDecline}
        title="Payment Request"
        actionType={actionType}
        alertInfo={`Clicking accept will send ${Number(
          paymentRequest?.cryptoAmount
        ).toString()} ${
          paymentRequest?.cryptoCurrency
        } from your account to the merchants account.`}
      >
        <div className="current-balance">
          {paymentRequest && (
            <>
              Current Balance
              <b>
                {requestBalance?.amount} {requestBalance?.currency}
              </b>
            </>
          )}
        </div>
        <div className="payment-request-wrapper">
          <div className="payment-request-value">
            <div className="payment-request-amount">
              {Number(paymentRequest?.cryptoAmount).toString()}
            </div>
            <div className="payment-request-crypto">
              {paymentRequest?.cryptoCurrency}
            </div>
          </div>
          <div className="payment-request-title">
            From {paymentRequest?.sourceFrom}
          </div>
        </div>
        {requestBalance?.insufficientBalance ? (
          <div className="important-notice2">
            <div className="notice-logo">
              <div className="notice-logo-i"></div>
              <img src={icons.info_icone} alt="info" />
            </div>
            <div className="text-notice">
              <div className="text-notixe-content">
                <b className="f-600">Insufficient Balance</b>
                <br />
                You will be asked to top up
                <b className="f-600">
                  &nbsp;
                  {(
                    requestBalance?.insufficientBalance - requestBalance.amount
                  ).toFixed(2)}
                  &nbsp;
                  {requestBalance.currency}
                </b>
                <br /> if you continue with this transaction.
              </div>
            </div>
          </div>
        ) : null}
      </ConfirmPopup>
    </OffCanvas>
  );
};
