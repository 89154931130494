import { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { route } from "../utils/constants/route";
import { castBooleanString } from "../utils/helpers/general";

export const AuthRoute: FC<RouteProps> = (props) => {
  const accessToken = localStorage.getItem(localStorageVariable.ACCESS_TOKEN);
  const refreshToken = localStorage.getItem(localStorageVariable.REFRESH_TOKEN);
  const userIsActive = castBooleanString(
    localStorage.getItem(localStorageVariable.USER_IS_ACTIVE) || ""
  );

  return accessToken && refreshToken && userIsActive ? (
    <Redirect to={{ pathname: route.WALLET }} />
  ) : (
    <Route {...props} />
  );
};
