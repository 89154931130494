import { useMutation } from "@tanstack/react-query";
import { FC } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import { useHistory } from "react-router-dom";
import { balanceApi } from "../../api/balance/balanceApi";
import { Button } from "../../components/Button";
import CloseNav from "../../components/CloseNav";
import { route } from "../../utils/constants/route";
import { succesToast } from "../../utils/helpers/alert";
import useForm from "../../utils/hooks/useForm";
import "./CreditCardForm.css";

const CreditCardForm: FC = () => {
  const history = useHistory();
  const { handleChange, handleFocus, handleSubmit, values, errors } = useForm();

  const isValid = Object.values(errors).every((error) => error);

  const { mutate: addCardMutation, isLoading } = useMutation(
    balanceApi.addCard,
    {
      onSuccess: () => {
        succesToast({
          message: "Card added successfully",
        });
        history.push(route.PROFILE);
      },
    }
  );

  const addCard = (): void => {
    if (!isValid) {
      return;
    }

    const expire = values.cardExpiration.replace("/", "");
    const year = expire.slice(2, 6);
    const month = expire.slice(0, 2);

    const payload = {
      cardNumber: values.cardNumber.replaceAll(" ", ""),
      expMonth: month,
      expYear: year.length === 2 ? 20 + year : year,
      cvv: values.cardSecurityCode,
    };

    addCardMutation(payload);
  };

  return (
    <div className="content">
      <div className="card_wrapper">
        <div className="titleOfModal ">
          <CloseNav title="Add Card" route="back" />
        </div>

        <div className="box justify-content-center align-items-center">
          <div className="addCard_content">
            <div className="creditCard">
              <Cards
                cvc={values.cardSecurityCode}
                expiry={values.cardExpiration}
                focused={values.focus}
                name={values.cardName}
                number={values.cardNumber}
              />
            </div>
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <label>Cardholder Name</label>
                <Form.Control
                  type="text"
                  id="cardName"
                  data-testid="cardName"
                  name="cardName"
                  value={values.cardName.toUpperCase()}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  isValid={errors.cardName}
                  className={"cardInput"}
                />
              </Form.Group>
              <Form.Group>
                <label>Card Number</label>
                <Form.Control
                  type="text"
                  id="cardNumber"
                  data-testid="cardNumber"
                  name="cardNumber"
                  inputMode="numeric"
                  value={values.cardNumber}
                  isValid={errors.cardNumber}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  style={{ marginBottom: "10px" }}
                  className="cardInput"
                />
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group>
                    <label className="securityLabel"> Security Code</label>
                    <Form.Control
                      type="number"
                      id="cardSecurityCode"
                      data-testid="cardSecurityCode"
                      name="cardSecurityCode"
                      inputMode="numeric"
                      value={values.cardSecurityCode}
                      onChange={handleChange}
                      onFocus={handleFocus}
                      isValid={errors.cardSecurityCode}
                      className={"cardInput"}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <div>
                      <label>Expiration Date</label>
                      <div>
                        <Form.Control
                          type="text"
                          id="cardExpiration"
                          data-testid="cardExpiration"
                          name="cardExpiration"
                          placeholder="MM / YY"
                          inputMode="numeric"
                          value={values.cardExpiration}
                          onChange={handleChange}
                          onFocus={handleFocus}
                          isValid={errors.cardExpiration}
                          className={"cardInput"}
                        />
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <div className="d-flex justify-content-center mt-5">
                <Button
                  disabled={!isValid}
                  loading={isLoading}
                  id="validateButton"
                  type="submit"
                  onClick={addCard}
                >
                  Save
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditCardForm;
