export default [
  {
    title: "What is Digital Nugget? ",
    description:
      "Digital Nugget is an online platform dedicated to gold trading, offering a secure and efficient way to buy and sell gold.",
  },
  {
    title: "How does Digital Nugget ensure security?",
    description:
      "We use advanced encryption, two-factor authentication, and conduct regular security audits to protect your transactions and personal information.",
  },
  {
    title: "How can I buy gold on Digital Nugget? ",
    description:
      "Create an account, go to our marketplace, choose the amount of gold to purchase, and complete the transaction following our prompts.",
  },
  {
    title: "What payment methods are accepted? ",
    description: "Credit/debit cards are accepted.",
  },
  {
    title: "Can I buy gold at any time? ",
    description:
      "Yes, our platform is available 24/7 for you to make purchases.",
  },
  {
    title: "How do I sell gold on Digital Nugget?",
    description:
      "Log in, select the gold amount you want to sell from your holdings, and confirm the sale. We ensure a smooth transaction.",
  },
];
