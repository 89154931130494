import CloseNav from "components/CloseNav";
import Footer from "components/Footer";
import Sidebar from "components/Sidebar";
import { FC, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { route } from "../utils/constants/route";

export const BuyTopUp: FC = () => {
  const history = useHistory();
  const topUpURL = history.location.state as string;

  useEffect(() => {
    if (!topUpURL) {
      history.push(route.BUY);
    }
  }, [topUpURL]);

  return (
    <>
      <div className="content">
        <Sidebar activeRoute={route.WALLET} />
        <div className="tab-content">
          <CloseNav title={"Top Up | Buy"} route={route.BUY} />
          <iframe src={topUpURL} className="top-up-iframe" />
        </div>
      </div>
      <Footer />
    </>
  );
};
