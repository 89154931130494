import { useQuery } from "@tanstack/react-query";
import * as classNames from "classnames";
import { FC } from "react";
import { Link, useHistory } from "react-router-dom";
import { balanceApi } from "../api/balance/balanceApi";
import { Button } from "../components/Button";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { SkeletonList } from "../components/SkeletonList";
import { icons } from "../utils/constants/icons";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { thousandSeperated } from "../utils/helpers/general";
import { useSearchParams } from "../utils/hooks/useSearchParams";
import { TabTitle } from "../utils/pagetitle";

const mobileOptions = [
  {
    name: "Buy",
    logo: icons.topUp,
    route: route.BUY,
  },
  // {
  //   name: "Sell",
  //   logo: icons.withdraw_icon,
  //   route: route.SELL,
  // },
  {
    name: "Swap | Convert",
    logo: icons.convertIcon,
    route: route.CONVERT,
  },
  {
    name: "Transfer",
    logo: icons.topUp,
    route: route.TRANSFER,
  },
];

const Wallet: FC = () => {
  TabTitle("Digital Nugget | Wallet");
  const history = useHistory();
  const searchParams = useSearchParams();

  const { data: wallet } = useQuery([queryNames.WALLET], balanceApi.getState);
  const { data: transactions } = useQuery(
    [queryNames.TRANSACTIONS],
    () => balanceApi.getTransactions(1),
    {
      onSuccess({ data }) {
        const transaction = data[0];

        if (
          transaction.type === "crypto_purchase" &&
          transaction.status === "FULFILLED" &&
          transaction.id === +searchParams?.transactionId
        ) {
          history.push({
            pathname: route.TOP_UP_SUCCESS,
            state: transaction,
          });
        }
      },
      enabled: !!history.location.search,
    }
  );

  return (
    <>
      <div className="content">
        <Sidebar activeRoute={route.WALLET} />
        <div className="tab-content logged-content">
          {wallet && (history.location.search ? transactions : true) ? (
            <div className="tab-content__wrapper">
              <div className="settings_tittle">
                <div className="balanceHeader">
                  <div className="tab-content__header-text">
                    <div className="tab-content__header-title">Balance</div>
                    <div className="tab-content__header-value">
                      {wallet?.currency} {thousandSeperated(wallet.balance)}
                      <span></span>
                    </div>
                  </div>
                  <div className="tab-content__header__buttons">
                    <Link to={route.BUY}>
                      <Button className="button">Buy</Button>
                    </Link>
                    <Link to={route.SELL}>
                      <Button variaint="transparent">Sell</Button>
                    </Link>
                  </div>
                </div>
                <div className="mobile-menu">
                  {mobileOptions.map((row, index) => {
                    return (
                      <Link
                        to={row.route}
                        className={classNames("mobile-menu__item", {
                          borderNone: mobileOptions[4],
                        })}
                        key={index}
                      >
                        <div className="mobile-menu__item-content">
                          <div className="mobile-menu__item-icon">
                            <img src={row.logo} alt="" />
                          </div>
                          <div className="mobile-menu__item-text">
                            {row.name}
                          </div>
                        </div>

                        <img src={icons.nextArrow} alt="" />
                      </Link>
                    );
                  })}
                </div>
                <div className="walet__items d-flex space-between align-start justify-start">
                  <div className="cryptosDiv">
                    {wallet.cryptos.map((row, index: number) => {
                      return (
                        <div className="walet__item" key={index}>
                          <div className="walet__item-left">
                            <div className="walet__icon">
                              <img src={row.icon} alt="" />
                            </div>
                            <div className="walet__item-content">
                              <div className="walet__item-title">
                                {row.name}
                              </div>
                              <div className="walet__item-text">
                                {row.symbol === "USDT" || row.cryptoAmount === 0
                                  ? row.cryptoAmount.toFixed(2)
                                  : row.cryptoAmount}{" "}
                                {row.symbol}
                              </div>
                            </div>
                          </div>
                          <div className="walet__item-value">
                            {thousandSeperated(row.monetaryAmount)}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="tab-content__wrapper loading-skeleton">
              <div className="settings_tittle">
                <div className="balanceHeader ">
                  <div className="tab-content__header-text">
                    <div className="tab-content__header-title ">
                      <div className="skeleton-text"></div>
                    </div>
                    <div className="tab-content__header-value ">
                      <div className="skeleton-text balanaceSkeleton"></div>
                      <div className="skeleton-text amountSkeleton"></div>
                    </div>
                  </div>
                  <div className="tab-content__header__buttons">
                    <Button className="skeletonBtnBorder"></Button>
                    <Button
                      variaint="transparent"
                      className="skeletonBtnBorder"
                    ></Button>
                  </div>
                </div>
                <div className="mobile-menu">
                  {mobileOptions.map((row) => {
                    return (
                      <div
                        className={classNames("mobile-menu__item", {
                          borderNone: mobileOptions[4],
                        })}
                        key={row.name}
                      >
                        <div className="mobile-menu__item-content">
                          <img
                            className="loading-skeleton image_menu_skeleton"
                            alt="skeleton"
                          />
                          <div className="mobile-menu__item-text mobile-menu_skeleton"></div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="walet__items-skeleton">
                  <SkeletonList number={5} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Footer active4="active" />
    </>
  );
};

export default Wallet;
