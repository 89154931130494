export const queryNames = {
  WALLET: "wallet",
  SUMSUB_ACCESS_TOKEN: "sumsubAccessToken",
  TRANSACTIONS: "transactions",
  USER: "user",
  TERMS: "terms",
  COUNTRIES: "countries",
  PENDING_REQUEST: "pendingRequest",
  BALANCE: "balance",
  POLICY: "policy",
  EXCHANGE_VALUE: "exchangeValue",
  COUNTRY_CODE: "countryCode",
  CURRENCIES: "currencies",
  GLOBAL_SETTINGS: "globalSettings",
  PAYMENT_STATUS: "paymentStatus",
} as const;
