import btc from "../../assets/images/currency/btc.svg";
import usd from "../../assets/images/currency/dollar.svg";
import ethereum from "../../assets/images/currency/ethereum.svg";
import eur from "../../assets/images/currency/eur.svg";
import euroc from "../../assets/images/currency/euroc.svg";
import gbp from "../../assets/images/currency/gbp.svg";
import usdt from "../../assets/images/currency/usdt.svg";
import elipseICON from "../../assets/images/icons/ElipseICON.svg";
import feedback from "../../assets/images/icons/Feedback.svg";
import search_icon from "../../assets/images/icons/IconSearch.svg";
import unselect from "../../assets/images/icons/Unselect.svg";
import backArrow from "../../assets/images/icons/arrowBack.svg";
import arrowDown from "../../assets/images/icons/arrowDown.svg";
import bell_icon from "../../assets/images/icons/bellIcon.svg";
import cancel from "../../assets/images/icons/cancel.svg";
import card_icon from "../../assets/images/icons/card.svg";
import {
  default as checkIcon,
  default as check_icon,
} from "../../assets/images/icons/checkicon.svg";
import checkmark from "../../assets/images/icons/checkmark.svg";
import close from "../../assets/images/icons/close.svg";
import completeTick from "../../assets/images/icons/completedTick.svg";
import convertIcon from "../../assets/images/icons/convert.svg";
import downArrow from "../../assets/images/icons/downArrow.svg";
import edit_icone from "../../assets/images/icons/edit_icone.svg";
import elipsIcon from "../../assets/images/icons/elips.svg";
import incorrect_icon from "../../assets/images/icons/incorrect_icon.svg";
import info_icone from "../../assets/images/icons/info_icone.svg";
import menu_icon from "../../assets/images/icons/menu_icon.svg";
import withdraw_icon from "../../assets/images/icons/mi2.svg";
import nextArrow from "../../assets/images/icons/nextArrow.svg";
import noInternet from "../../assets/images/icons/noInternet.svg";
import pending from "../../assets/images/icons/pending.svg";
import pendingRequest from "../../assets/images/icons/pendingRequest.svg";
import previousArrow from "../../assets/images/icons/previousArrow.svg";
import privacyPolicy_icon from "../../assets/images/icons/privacyPolicy.svg";
import rejectTick from "../../assets/images/icons/rejectTick.svg";
import requests_icon from "../../assets/images/icons/requests_icon.svg";
import securityIcon from "../../assets/images/icons/securityIcon.svg";
import successIcon from "../../assets/images/icons/successIcon.svg";
import swap_icon from "../../assets/images/icons/swap_icon.svg";
import termsIcon from "../../assets/images/icons/termsIcon.svg";
import tickIcon from "../../assets/images/icons/tick.svg";
import topUp from "../../assets/images/icons/topUp.svg";
import transactions from "../../assets/images/icons/transactions.svg";
import userIcon from "../../assets/images/icons/userIcon.svg";
import wallet_icon from "../../assets/images/icons/wallet_icon.svg";
import fair_price from "../../assets/images/icons/fairPrice.svg";
import high_limits from "../../assets/images/icons/highLimits.svg";
import arrow_right from "../../assets/images/icons/arrow-right.svg";
import arrow_up_active from "../../assets/images/icons/arrow-up-active.svg";
import plus from "../../assets/images/icons/plus.svg";
import minus from "../../assets/images/icons/minus.svg";
import pci from "../../assets/images/icons/pci.svg";
import digital_nugget_logo from "../../assets/images/icons/digital_nugget.svg";
import real_pricing from "../../assets/images/icons/real-pricing.svg";
import digital_nugget_footer_logo from "../../assets/images/icons/digital_nugget_footer_logo.svg";
import au_logo from "../../assets/images/icons/au.svg";
import edit_simple_icon from "../../assets/images/icons/edit_simple_icon.svg";
import logout_logo from "../../assets/images/icons/logout-icon.svg";
import close_icon_modal from "../../assets/images/icons/close_icon_modal.svg";
import save_icon from "../../assets/images/icons/save_logo.svg";
import cancel_icon from "../../assets/images/icons/cancel_icon.svg";

export const icons = {
  rejectTick,
  pending,
  noInternet,
  close,
  completeTick,
  convertIcon,
  arrowDown,
  pendingRequest,
  transactions,
  usd,
  eur,
  gbp,
  btc,
  ethereum,
  euroc,
  usdt,
  previousArrow,
  nextArrow,
  backArrow,
  bell_icon,
  card_icon,
  feedback,
  elipseICON,
  search_icon,
  check_icon,
  downArrow,
  unselect,
  edit_icone,
  checkmark,
  incorrect_icon,
  topUp,
  menu_icon,
  info_icone,
  withdraw_icon,
  privacyPolicy_icon,
  requests_icon,
  securityIcon,
  successIcon,
  swap_icon,
  termsIcon,
  userIcon,
  cancel,
  wallet_icon,
  checkIcon,
  tickIcon,
  elipsIcon,
  fair_price,
  high_limits,
  arrow_right,
  arrow_up_active,
  plus,
  minus,
  pci,
  digital_nugget_logo,
  real_pricing,
  digital_nugget_footer_logo,
  au_logo,
  edit_simple_icon,
  logout_logo,
  close_icon_modal,
  save_icon,
  cancel_icon,
} as const;

export const allflags =
  "https://swifty-global-app-assets-central.s3.eu-west-1.amazonaws.com/flags/countries/png/";
