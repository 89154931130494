import * as classNames from "classnames";
import { FC } from "react";
import Spinner from "react-bootstrap/Spinner";

type SpinerProps = {
  sell?: boolean;
};

const Spiner: FC<SpinerProps> = ({ sell }) => {
  return (
    <div className={classNames("spinnerPosition", { sell })}>
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};

export default Spiner;
