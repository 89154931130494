import * as classNames from "classnames";
import { FC } from "react";
import { Link } from "react-router-dom";
import { route } from "../utils/constants/route";
import { icons } from "utils/constants/icons";

type SidebarProps = {
  activeRoute?: string;
};

const Sidebar: FC<SidebarProps> = ({ activeRoute }) => {
  const cardsInput = [
    {
      name: "Wallet",
      route: route.WALLET,
    },
    {
      name: "Pending Requests",
      route: route.PENDING_REQUEST,
    },
    {
      name: "Transactions",
      route: route.TRANSACTIONS,
    },
    // {
    //   name: "Transfer",
    //   route: route.TRANSFER,
    // },
    // {
    //   name: "Convert",
    //   route: route.CONVERT,
    // },
    // {
    //   name: "Settings",
    //   route: route.SETTINGS,
    // },
    {
      name: "Profile",
      route: route.PROFILE,
    },
  ];

  return (
    <div className="sidebar">
      <Link to={route.WALLET} className="sidebar__logo">
        <img src={icons.digital_nugget_logo} alt="logo" />
      </Link>
      <div className="sidebar__menu">
        {cardsInput.map((card, index) => {
          return (
            <div key={index} data-id={index}>
              <Link
                to={card.route}
                className={classNames("sidebar__menu-link", {
                  active: activeRoute === card.route,
                })}
              >
                {card.name}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
