import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { authApi } from "../api/auth/authApi";
import { AuthPayload, AuthResponse } from "../api/auth/authSchema";
import { Button } from "../components/Button";
import FooterOfWeb from "../components/FooterOfWeb";
import OtpInput from "../components/OtpInput";
import { ResendCode } from "../components/ResendCode";
import { messagingGetToken } from "../firebase";
import { localStorageVariable } from "../utils/constants/localStorageVariables";
import { queryNames } from "../utils/constants/queryNames";
import { route } from "../utils/constants/route";
import { TabTitle } from "../utils/pagetitle";
import { icons } from "utils/constants/icons";

const Otp: FC = () => {
  TabTitle("Digital Nugget | Otp");

  const history = useHistory();
  const queryClient = useQueryClient();

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const otpInputValue = otp.join("");

  const { mutate: verifyMutation, isLoading } = useMutation(authApi.verifyOtp, {
    onSuccess: (data) => {
      queryClient.setQueriesData([queryNames.USER], data.userData);
      const isRegistered =
        data?.userData?.firstName &&
        data?.userData?.lastName &&
        !!data?.userData?.country;

      localStorage.setItem(localStorageVariable.ACCESS_TOKEN, data.accessToken);
      localStorage.setItem(
        localStorageVariable.REFRESH_TOKEN,
        data.refreshToken
      );
      localStorage.setItem(
        localStorageVariable.USER_IS_ACTIVE,
        isRegistered?.toString() || "false"
      );

      if (!isRegistered) {
        history.push(route.SIGNUP);

        return;
      }

      messagingGetToken();

      if (data?.userData?.kycStatus === "unverified") {
        history.push(route.VERIFY_IDENTITY);
      } else if (localStorage.getItem(localStorageVariable.EXCHANGE)) {
        history.push(route.BUY_PAYMENT);
      } else {
        history.push(route.WALLET);
      }

      localStorage.removeItem(localStorageVariable.OTP);
      localStorage.removeItem(localStorageVariable.PHONE_NUMBER);
    },
  });

  const verifyCode = async (): Promise<void> => {
    if (otpInputValue.length !== 6) {
      return;
    }

    const payload = {
      otp: Number(otpInputValue),
    };

    verifyMutation(payload);
  };

  useEffect(() => {
    if (
      localStorage.getItem(localStorageVariable.REFRESH_TOKEN) &&
      localStorage.getItem(localStorageVariable.USER_IS_ACTIVE)
    ) {
      localStorage.clear();
      history.push(route.LOGIN);
    }
  }, []);

  return (
    <>
      <div className="containerr">
        <header className="header">
          <div className="header__container">
            <div className="logo">
              <Link to={route.HOME}>
                <img src={icons.digital_nugget_logo} alt="logo" />
              </Link>
            </div>
          </div>
        </header>

        <div className="verify-wrapper">
          <div className="sub-header">
            <h2 className="h2">Verify your mobile number</h2>
            <div className="sub-title">
              Please type the verification code sent to&nbsp;
              {localStorage.getItem(localStorageVariable.PHONE_NUMBER)}
            </div>
          </div>
          <div className="otp-input-container">
            <OtpInput otp={otp} setOtp={setOtp} onSubmit={verifyCode} />
          </div>
          <ResendCode<AuthPayload, AuthResponse>
            resendCode={authApi.signIn}
            payload={{
              phoneNumber:
                localStorage.getItem(localStorageVariable.PHONE_NUMBER) || "",
            }}
            onSuccess={(data) => {
              localStorage.setItem(
                localStorageVariable.OTP,
                data.loginOtp.toString()
              );
              setOtp(new Array(6).fill(""));
            }}
          />
          <div className="verify-footer">
            Wrong number?{" "}
            <Link to={route.LOGIN} className="verify-footer__link">
              Click here to change
            </Link>
          </div>
          <div className="verify-button">
            <Button
              disabled={otpInputValue.length < 6}
              loading={isLoading}
              onClick={verifyCode}
            >
              Continue
            </Button>
          </div>
        </div>
        <FooterOfWeb />
      </div>
    </>
  );
};

export default Otp;
